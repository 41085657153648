import PropTypes from 'prop-types';
import React from 'react';

export default function BasicLayout({ children }) {
  return (
    <div className="max-w-screen-xl grid grid-cols-1 mx-auto px-4 sm:px-6 md:px-8">
      <div className="py-4">{children}</div>
    </div>
  );
}

BasicLayout.propTypes = {
  children: PropTypes.any.isRequired,
};
