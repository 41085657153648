import { PHONE_REGEX } from 'utils/regex';
import * as Yup from 'yup';

export const ProfilePsychologistSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  biography: Yup.string(),
  email: Yup.string().email('Invalid email'),
  homePhone: Yup.string().default('').matches(PHONE_REGEX, 'Invalid phone number'),
  cellPhone: Yup.string().default('').matches(PHONE_REGEX, 'Invalid cell phone number'),
  address: Yup.object().shape({
    country: Yup.string(),
    state: Yup.string(),
    timezone: Yup.string(),
    aka: Yup.string(),
    city: Yup.string(),
    street: Yup.string(),
    extNumber: Yup.string(),
    intNumber: Yup.string(),
    zipCode: Yup.string(),
    default: Yup.boolean(),
  }),
});
