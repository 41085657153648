import 'react-datepicker/dist/react-datepicker.css';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import TimezonedNextSessionSchedule from 'components/TimezonedNextSessionSchedule';
import Title from 'components/Title';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { RescheduleSchema } from 'schemas/sessions';
import { createReschedule } from 'services/tickets';
import { getToken } from 'state/auth';
import { isPassedTime } from 'utils/dates';

export default function Finalize() {
  // Global state
  const token = useRecoilValue(getToken);
  const { session, refetchSession } = useOutletContext();

  // RRD
  const navigate = useNavigate();

  // RQ
  const createRescheduleActionMutation = useMutation((data) => createReschedule(token, data), {
    onSuccess: async () => {
      await refetchSession();
      toast.success('El horario de la sesión se actualizó correctamente');
      navigate(-1);
    },
    // eslint-disable-next-line no-console
    onError: () => toast.error('Algo salió mal'),
  });

  // RHF
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedDate: null,
    },
    resolver: yupResolver(RescheduleSchema),
  });

  const watchSelectedDate = watch('selectedDate');
  let scheduleSimulator = null;
  if (watchSelectedDate) {
    const startDateUTC = moment.utc(watchSelectedDate);
    const endDateUTC = startDateUTC.clone().add(1, 'hour');
    const localTimezone = moment.tz.guess(true);

    scheduleSimulator = (
      <div>
        <Title>Simulación de horarios</Title>
        <br />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <TimezonedNextSessionSchedule
            title={`Navegador (${localTimezone})`}
            startDateUTC={startDateUTC}
            endDateUTC={endDateUTC}
            timezone={localTimezone}
          />
          <TimezonedNextSessionSchedule
            title={`Default (${session.psychologist.timezone})`}
            startDateUTC={startDateUTC}
            endDateUTC={endDateUTC}
            timezone={session.psychologist.timezone}
          />

          <TimezonedNextSessionSchedule
            title={`Paciente (${session.patient.timezone})`}
            startDateUTC={startDateUTC}
            endDateUTC={endDateUTC}
            timezone={session.patient.timezone}
          />
        </div>
      </div>
    );
  }

  const onSubmit = ({ description, selectedDate }) => {
    const parsedData = {
      title: `CATHER RESCHEDULE SESSION: ${session.id}`,
      description,
      selected_date: selectedDate,
      session: session.id,
    };
    createRescheduleActionMutation.mutate(parsedData);
  };

  return (
    <div className="mt-9">
      <Title className="font-body leading-6 text-lg text-primary">Reagendar la sesión</Title>
      <br />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <TextArea
          id="description"
          disabled={createRescheduleActionMutation.isLoading}
          labelClassName="text-sm text-gray-700 leading-5 font-medium"
          textLabel="Cuéntanos por qué se reagendará esta sesión."
          register={register}
          registerConfig={{ required: true }}
          error={errors.description?.message}
        />
        <br />
        <Controller
          control={control}
          name={`selectedDate`}
          render={(_props) => (
            <>
              <label
                className={`font-body font-bold text-sm text-gray-700 leading-5 font-medium `}
                htmlFor="selectedDate"
              >
                Seleccione una fecha (Hora del navegador)
              </label>
              <DatePicker
                disabled={createRescheduleActionMutation.isLoading}
                className="w-full rounded-md border-primary"
                selected={_props.field?.value}
                onChange={(date) => {
                  _props.field.onChange(date);
                }}
                showTimeSelect
                minDate={new Date()}
                filterTime={isPassedTime}
                dateFormat="yyyy-MM-dd HH:mm"
              />
              {errors?.selectedDate?.message ? (
                <p className="text-red-500 text-sm font-body">{errors.selectedDate.message}</p>
              ) : null}
            </>
          )}
        />
        <br />
        {scheduleSimulator}
        <br />
        <div className="w-full">
          <p className="text-red-500 mb-2">{errors?.tasks ? errors?.tasks?.message : ''}</p>
          <Button
            disabled={createRescheduleActionMutation.isLoading}
            type="submit"
            className="bg-secondary rounded-md  text-white"
          >
            Reagendar sesión
          </Button>

          <Button
            disabled={createRescheduleActionMutation.isLoading}
            onClick={() => navigate(-1)}
            className="mt-4 bg-red-500 rounded-md text-white"
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
}
