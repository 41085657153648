import PropTypes from 'prop-types';

export default function TimezonedNextSessionSchedule({
  title,
  startDateUTC,
  endDateUTC,
  timezone,
}) {
  const format = 'DD[ de ]MMMM[ del ]YYYY [a las] H:mm';

  const timezonedStartDate = startDateUTC.tz(timezone).format(format);
  const timezonedEndDate = endDateUTC.tz(timezone).format(format);

  return (
    <div>
      <p className="font-bold font-header text-sm text-gray-500 mb-2">{title}</p>
      <div className="flex flex-col md:flex-row justiry-between">
        <div className="mb-2 mr-4">
          <div className="grid grid-cols-4 gap-1">
            <p className="col-span-2 text-gray-400">Inicia:</p>
            <p className="col-span-4 text-gray-600">{timezonedStartDate}</p>
          </div>
          <div className="grid grid-cols-4 gap-1">
            <p className="col-span-2 text-gray-400">Termina:</p>
            <p className="col-span-4 text-gray-600">{timezonedEndDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

TimezonedNextSessionSchedule.propTypes = {
  title: PropTypes.string,
  startDateUTC: PropTypes.any.isRequired,
  endDateUTC: PropTypes.any.isRequired,
  timezone: PropTypes.string.isRequired,
};

TimezonedNextSessionSchedule.defaultProps = {
  title: 'Horario Siguiente Sesión:',
};
