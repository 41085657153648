import {
  SESSION_CANCELED,
  SESSION_CREATED,
  SESSION_FINALIZED,
  SESSION_IN_PROGRESS,
  SESSION_PAYED,
  SESSION_SCHEDULED,
  SESSION_TIME_COMPLETED,
  SESSION_UNPAYED,
} from './statuses';

export const sessionStatuses = {
  [SESSION_CREATED]: 'Creada',
  [SESSION_SCHEDULED]: 'Agendada',
  [SESSION_PAYED]: 'Pagada',
  [SESSION_UNPAYED]: 'Sin pago',
  [SESSION_CANCELED]: 'Cancelada',
  [SESSION_IN_PROGRESS]: 'En progreso',
  [SESSION_TIME_COMPLETED]: 'Termino la sesión',
  [SESSION_FINALIZED]: 'Finalizada',
};

export const sessionStatusesTagColor = {
  [SESSION_CREATED]: 'primary',
  [SESSION_SCHEDULED]: 'secondary',
  [SESSION_PAYED]: 'tertiary',
  [SESSION_UNPAYED]: 'fourth',
  [SESSION_CANCELED]: 'fifth',
  [SESSION_IN_PROGRESS]: 'sixth',
  [SESSION_TIME_COMPLETED]: 'seven',
  [SESSION_FINALIZED]: 'eigthth',
};
