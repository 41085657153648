import { XIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React from 'react';

let colorStatus = {
  primary: 'bg-tag_status_1 text-tag_status_1',
  secondary: 'bg-tag_status_2 text-tag_status_2',
  tertiary: 'bg-tag_status_3 text-tag_status_3',
  fourth: 'bg-tag_status_4 text-tag_status_4',
  fifth: 'bg-tag_status_5 text-tag_status_5',
  sixth: 'bg-tag_status_6 text-tag_status_6',
  seventh: 'bg-tag_status_7 text-tag_status_7',
  eighth: 'bg-tag_status_8 text-tag_status_8',
  nineth: 'bg-tag_status_9 text-tag_status_9',
  tenth: 'bg-tag_status_10 text-tag_status_10',
};

export default function Tag({ tag, color, withX, withDot }) {
  return (
    <div className="cursor-pointer flex items-baseline">
      <span
        className={`font-body font-bold inline-flex flex justify-center items-center text-center px-3 py-0.5 rounded-full text-xs leading-4 ${
          color ? colorStatus[String(color)] + ' font-normal' : 'text-gray-500 font-medium	'
        }`}
      >
        {withDot && (
          <svg className="-ml-0.5 mr-1.5 h-2 w-2" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
          </svg>
        )}
        {tag}
        {withX && (
          <XIcon className={`h-3 w-3 ml-1 mt-px text-white ${colorStatus[String(color)]}`} />
        )}
      </span>
    </div>
  );
}

Tag.propTypes = {
  tag: PropTypes.string,
  color: PropTypes.string,
  status: PropTypes.string,
  withX: PropTypes.bool,
  withDot: PropTypes.bool,
};

Tag.defaultProps = {
  withX: false,
  withDot: false,
};
