import Loader from 'components/Loader';
import React from 'react';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getPsychologistProfile } from 'services/psychologist';
import { getToken } from 'state/auth';

export default function Profile() {
  // Global states
  const token = useRecoilValue(getToken);

  const psychologistProfileQuery = useQuery(
    ['psychologistProfile', token],
    () => getPsychologistProfile(token),
    { refetchOnWindowFocus: false },
  );

  return (
    <>
      {psychologistProfileQuery.isLoading && (
        <div className="font-body text-center">
          <Loader /> Procesando...
        </div>
      )}
      {psychologistProfileQuery.isSuccess && <Outlet context={psychologistProfileQuery} />}
    </>
  );
}
