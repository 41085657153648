import { CalendarIcon, ClockIcon, EyeIcon } from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import Divider from 'components/Divider';
import EducationItem from 'components/EducationItem';
import Loader from 'components/Loader';
import Title from 'components/Title';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getPsychologistSessions } from 'services/psychologist';
import { getUser } from 'state/auth';
import { selectedSession as selectedSessionState } from 'state/session';
import { getDateAndTimezone, getMomentDateCalendar } from 'utils/dates';

export default function CalendarSessions() {
  // Global states
  const user = useRecoilValue(getUser);
  const [, setSelectedSession] = useRecoilState(selectedSessionState);

  // RRD
  const navigate = useNavigate();

  // eslint-disable-next-line sonarjs/no-unused-collection
  const selectedDates = [];
  const dateFormat = 'DD/MM/YYYY';

  // Query
  const { data, status } = useQuery(['psychologistSessions', user.id], () =>
    getPsychologistSessions(user.id),
  );

  const renderEverySection = (session, patient) => {
    return (
      <EducationItem
        key={session.id}
        title={`Sesión ${session.number}`}
        className="flex-1 lg:max-w-336px md:min-w-300px md:m-2 md:rounded-lg md:shadow-md cursor-pointer hover:bg-gray-50"
        callback={() => {
          setSelectedSession(session.id);
          navigate('detail');
        }}
        iconSpanItem={[
          {
            icon: <CalendarIcon className="h-6 w-6 mr-2 text-gray-400" />,
            text: (
              <p className="font-body text-xs font-medium text-gray-400 m-0">
                {getDateAndTimezone(session.start_date, dateFormat).sessionDate}
              </p>
            ),
          },
          {
            icon: <ClockIcon className="h-6 w-6 mr-2 text-gray-400" />,
            text: (
              <p className="font-body text-xs font-medium text-gray-400 m-0">
                {getDateAndTimezone(session.start_date, dateFormat).sessionTime}
              </p>
            ),
          },
          {
            icon: <img className="h-6 w-6 mr-2 rounded-full" src={patient.photo_URL} alt="" />,
            text: (
              <p className="font-body  text-xs font-medium text-gray-400 m-0">
                <strong>{`${patient.first_name} ${patient.last_name}`}</strong>
              </p>
            ),
          },
        ]}
      >
        {<EyeIcon className="h-6 w-6 text-gray-400" />}
      </EducationItem>
    );
  };

  const renderSessionSections = (data) => {
    return (
      <div>
        {data.today && (
          <div>
            <Title className="text-black text-base md:hidden">Hoy</Title>
            <div className="flex flex-col mb-8 md:my-0 border rounded-md border-gray-300 md:border-none">
              <Divider
                text="Hoy"
                centered={false}
                textColor="text-black"
                className="my-3 hidden md:block"
              />
              <div className="flex flex-col md:flex-row md:flex-wrap align-items rounded-lg">
                {data.today.map((session) => {
                  const patient = session.accompaniment.patient;
                  selectedDates.push(getMomentDateCalendar(session.start_date));
                  return renderEverySection(session, patient);
                })}
              </div>
            </div>
          </div>
        )}
        {data.current_week && (
          <div>
            <Title className="text-black text-base md:hidden">Esta semana</Title>
            <div className="flex flex-col mb-8 md:my-0 border rounded-md border-gray-300 md:border-none">
              <Divider
                text="Esta semana"
                centered={false}
                textColor="text-black"
                className="my-3 hidden md:block"
              />
              <div className="flex flex-col md:flex-row md:flex-wrap align-items rounded-lg">
                {
                  // eslint-disable-next-line
                  data.current_week.map((session) => {
                    const patient = session.accompaniment.patient;
                    selectedDates.push(getMomentDateCalendar(session.start_date));
                    return renderEverySection(session, patient);
                  })
                }
              </div>
            </div>
          </div>
        )}
        {data.next_week && (
          <div>
            <Title className="text-black text-base md:hidden">Siguiente semana</Title>
            <div className="flex flex-col mb-8 md:my-0 border rounded-md border-gray-300 md:border-none">
              <Divider
                text="Siguiente semana"
                centered={false}
                textColor="text-black"
                className="my-3 hidden md:block"
              />
              <div className="flex flex-col md:flex-row md:flex-wrap align-items rounded-lg">
                {
                  // eslint-disable-next-line
                  data.next_week.map((session) => {
                    const patient = session.accompaniment.patient;
                    selectedDates.push(getMomentDateCalendar(session.start_date));
                    return renderEverySection(session, patient);
                  })
                }
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <BasicLayout title="Sesiones">
      <div className="lg:border lg:border-gray-300 rounded-md md:shadow-none md:border-none">
        {status == 'loading' && (
          <>
            <Loader /> Procesando...
          </>
        )}
        {status == 'success' && renderSessionSections(data)}
        {/* Error */}
      </div>
    </BasicLayout>
  );
}
