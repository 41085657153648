import PropTypes from 'prop-types';
import React from 'react';

import Tag from './Tag';

export default function BasicCard({
  firstText,
  secondText,
  tag,
  icon,
  image,
  colorTag,
  imgSrc,
  callback,
}) {
  return (
    <button
      id="BasicCard"
      onClick={callback}
      className={`w-full relative overflow-hidden rounded-3xl filter shadow-sm hover:shadow-md cursor-pointer ${
        !imgSrc && 'border'
      }`}
    >
      <div
        className={`z-10 w-full py-5 px-4 flex items-center justify-between ${
          imgSrc && 'absolute'
        }`}
      >
        <div className="w-full ml-2">
          <div className="flex justify-between">
            {firstText && <p className="font-body font-semibold m-0 mr-2">{firstText}</p>}
            {tag && <Tag tag={tag} color={colorTag} />}
          </div>
          <div className="text-left">
            <p className="font-body text-gray-500 m-0">{secondText}</p>
          </div>
        </div>
        {icon ? (
          <div className="bg-primary rounded-full p-2">{icon}</div>
        ) : (
          image && <img className="rounded-full h-10 w-10" src={image} alt="" />
        )}
      </div>
      {imgSrc && (
        <img
          className={`w-full object-cover rounded-3xl z-negative opacity-25 max-h-136 `}
          src={imgSrc}
          alt="People working on laptops"
        />
      )}
    </button>
  );
}

BasicCard.propTypes = {
  firstText: PropTypes.any,
  secondText: PropTypes.any,
  tag: PropTypes.string,
  icon: PropTypes.any,
  image: PropTypes.any,
  colorTag: PropTypes.string,
  imgSrc: PropTypes.string,
  callback: PropTypes.func,
};
