import { CometChatUI } from 'CometChatWorkspace/src';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function CometChat({ patientUID, showCallControls = true }) {
  /**
   * showCallControls props is to control if we want the audio call and video call buttons hidden
   */

  // Global states

  const navigate = useNavigate();

  const onCallEndedHandler = () => {
    navigate('/ic/sessions/detail/');
  };

  return (
    <CometChatUI
      showCallControls={showCallControls}
      showSwitchCameraButton={true}
      chatWithUser={patientUID}
      onCallEnded={onCallEndedHandler}
    />
  );
}

CometChat.propTypes = {
  patientUID: PropTypes.string,
  showCallControls: PropTypes.bool,
};
