import PropTypes from 'prop-types';
import React from 'react';

export default function TextArea({
  textLabel,
  labelClassName,
  textAreaClassName,
  placeholder,
  rows,
  cols,
  id,
  disabled,
  register,
  registerConfig,
  error,
}) {
  // Classes
  let borderClasses = 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500';
  let labelClasses = 'text-gray-700';
  if (error) {
    labelClasses = 'text-pink-700';
    borderClasses = 'border-pink-300 focus:ring-pink-500 focus:border-pink-500 text-pink-500';
  }
  if (disabled) {
    labelClasses = 'text-gray-300';
    borderClasses = 'border-gray-300 text-gray-300';
  }
  return (
    <div className="flex flex-col">
      <label
        className={`font-body font-bold text-gray-700 ${labelClassName} ${labelClasses}`}
        htmlFor="contact"
      >
        {textLabel}
      </label>
      <textarea
        id={id}
        disabled={disabled}
        name={id}
        {...register(id, registerConfig)}
        className={`rounded-md border border-gray-300 text-gray-600 font-body text-sm ${textAreaClassName} ${borderClasses} mt-2 disabled:text-gray-500 disabled:cursor-not-allowed`}
        placeholder={placeholder}
        cols={cols}
        rows={rows}
      ></textarea>
      {error && <p className="text-sm font-body font-medium text-red-500 pt-2">{error}</p>}
    </div>
  );
}

TextArea.propTypes = {
  textLabel: PropTypes.any,
  labelClassName: PropTypes.string,
  textAreaClassName: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.any,
  cols: PropTypes.any,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  register: PropTypes.any,
  registerConfig: PropTypes.object,
  error: PropTypes.string,
};

TextArea.defaultProps = {
  rows: '10',
  cols: '30',
  error: undefined,
  register: undefined,
};
