import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import UserContent from './UserContent';

export default function AccompanimentDetail() {
  // Global states
  // const [accompaniment] = useRecoilState(accompanimentAtom);
  // const token = useRecoilValue(getToken);

  // Outlet context
  const accompanimentContext = useOutletContext();

  const patient = accompanimentContext.patient;
  console.log('🚀 ~ AccompanimentDetail ~ patient:', patient);

  return (
    <div className="flex flex-col md:flex-row box-border">
      <div className="flex-1 md:pr-8">
        <br />
        <UserContent user={patient} title="Patient" />

        <br />
      </div>
      <br className="lg:hidden" />
      <div className="flex-1 md:pr-8">
        <Outlet />
      </div>
    </div>
  );
}
