import { CheckIcon } from '@heroicons/react/solid';
import Divider from 'components/Divider';
import Loader from 'components/Loader';
import TaskItem from 'components/TaskItem';
import React from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { getAccompanimentTasks } from 'services/accompaniment';
import { accompaniment as accompanimentAtom } from 'state/accompaniment';
import { getToken } from 'state/auth';
import { TASK_STATUS } from 'utils/accompanimentConstants';
import { getDateAndTimezone } from 'utils/dates';

export default function Tasks() {
  // Global states
  const accompaniment = useRecoilValue(accompanimentAtom); // read-only access
  const token = useRecoilValue(getToken);

  // Query
  const { data, status } = useQuery(['accompanimentTasks', accompaniment.id, token], () =>
    getAccompanimentTasks(accompaniment.id, token),
  );

  const renderTasks = (tasks) => {
    return tasks.map((task, index) => {
      if (index == 0)
        return (
          <div className="" key={task.id}>
            <Divider borderClassName="border-gray-200 lg:hidden mb-10" />
            <TaskItem
              text={task.content}
              title={TASK_STATUS[task.status].text}
              date={`Sesión ${task.session.number} | ${
                getDateAndTimezone(task.session.start_date).sessionDate
              } `}
              icon={
                <div className="flex items-center justify-center min-h-64 min-w-64 rounded-md bg-yellow-300 text-white">
                  <CheckIcon className="h-6 w-6 text-white" />
                </div>
              }
            />
          </div>
        );
      return (
        <div className="my-4 lg:my-0" key={task.id}>
          <Divider borderClassName="border-gray-200 lg:hidden mb-10" />
          <TaskItem
            text={task.content}
            title={TASK_STATUS[task.status].text}
            date={`Sesión ${task.session.number} | ${
              getDateAndTimezone(task.session.start_date).sessionDate
            } `}
            icon={
              <div className="flex items-center justify-center min-h-64 min-w-64 rounded-md bg-pink-500 text-white">
                <CheckIcon className="h-6 w-6 text-white" />
              </div>
            }
          />
        </div>
      );
    });
  };

  return (
    <div>
      <Divider borderClassName="border-gray-200 hidden lg:block" />
      {status == 'loading' && (
        <div className="font-body text-center">
          <Loader /> Procesando...
        </div>
      )}
      {status == 'success' && data.length <= 0 && (
        <p className="font-body text-center">No hay tasks todavia</p>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 py-8">
        {status == 'success' && data.length > 0 && renderTasks(data)}
      </div>
    </div>
  );
}
