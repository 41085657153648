import { CheckIcon, XIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import Content from 'components/Content';
import CustomRadioGroup from 'components/Forms/CustomRadioGroup';
import CustomRadioGroupOption from 'components/Forms/CustomRadioGroupOption';
import Modal from 'components/Modal';
import Title from 'components/Title';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import {
  approveReschedule as approveRescheduleService,
  rejectReschedule as rejectRescheduleService,
} from 'services/tickets';
import { getToken } from 'state/auth';
import { STATUS_APPROVED, STATUS_IN_REVIEW, STATUS_REJECTED } from 'utils/constants/tickets';

const confirmModal = {
  type: 'success',
  title: 'Reagendar sesión',
  description: (
    <span>
      ¿Confirma que quiere aceptar esta solicitud?
      <br /> Está acción no se puede deshacer.
    </span>
  ),
  confirmButtonText: 'Reagendar',
  cancelButtonText: 'Cancelar',
  Icon: CheckIcon,
};

const cancelModal = {
  type: 'error',
  title: 'Rechazar ticket',
  description: (
    <span>
      ¿Confirma que quiere rechazar esta solicitud? <br />
      Esta acción no se puede deshacer.
    </span>
  ),
  confirmButtonText: 'Rechazar',
  cancelButtonText: 'Cancelar',
  Icon: XIcon,
};

export default function PatientReschedule({ ticket, refetchTicket }) {
  // Global state
  const token = useRecoilValue(getToken);

  // Local state
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalConfig, setModalConfig] = useState(confirmModal);
  const [confirmModalHandler, setConfirmModalHandler] = useState(() => () => null);

  const onMutateError = () => {
    setConfirmModalHandler(() => () => null);
    toast.error('Algo salio mal');
    refetchTicket();
  };

  // RQ
  const approveRescheduleMutation = useMutation(
    (data) => approveRescheduleService(token, ticket.id, data),
    {
      onSuccess: () => {
        setConfirmModalHandler(() => () => null);
        toast.success('Se reagendó la sesión.');
        refetchTicket();
      },
      onError: onMutateError,
    },
  );

  const rejectRescheduleMutation = useMutation(() => rejectRescheduleService(token, ticket.id), {
    onSuccess: () => {
      setConfirmModalHandler(() => () => null);
      toast.success('Se rechazo la solicitud.');
      refetchTicket();
    },
    onError: onMutateError,
  });

  const formatDate = (date) => {
    const localDate = moment.utc(date).local();
    return localDate.format('DD [de] MMMM [del] YYYY [a las] hh:mm a');
  };

  const buildOption = (date, index) => {
    const parsedLocalDate = formatDate(date);
    return (
      <CustomRadioGroupOption key={index} index={index} option={date}>
        {parsedLocalDate}
      </CustomRadioGroupOption>
    );
  };

  const onClickApproveHandler = () => {
    if (!selected) {
      setError('Por favor, seleccione una opción.');
      return;
    }
    setError(null);
    setConfirmModalHandler(() => onConfirmApproveHandler);
    setModalConfig(confirmModal);
    setOpenModal(true);
  };

  const onClickRejectHandler = () => {
    setConfirmModalHandler(() => onConfirmRejectHandler);
    setModalConfig(cancelModal);
    setOpenModal(true);
  };

  const onConfirmApproveHandler = () => {
    approveRescheduleMutation.mutate({ selected_date: selected });
  };

  const onConfirmRejectHandler = () => {
    rejectRescheduleMutation.mutate();
  };

  return (
    <div>
      <br />
      {ticket.status === STATUS_IN_REVIEW ? (
        <>
          <Title className="text-lg mb-2">Horarios propuestos por el paciente</Title>
          <CustomRadioGroup selected={selected} setSelected={setSelected}>
            {ticket.proposed_dates.map(buildOption)}
          </CustomRadioGroup>
          <br />
          {error ? <Content className="text-red-500 mb-4">{error}</Content> : null}
          <Button onClick={onClickApproveHandler} primary className="rounded-md mb-2">
            Aprobar
          </Button>
          <Button
            onClick={onClickRejectHandler}
            boxShadow={true}
            className="text-white bg-red-500 text-base leading-6 font-medium border border-red-300 rounded-md"
          >
            Rechazar
          </Button>
        </>
      ) : null}
      {ticket.status === STATUS_APPROVED ? (
        <>
          <Title className="text-lg mb-2">La sesión fue reagendada</Title>
          <Content>
            <p>
              Nueva fecha:{' '}
              <span className="text-md font-bold text-secondary">
                {formatDate(ticket.selected_date)}
              </span>
            </p>
          </Content>
        </>
      ) : null}
      {ticket.status === STATUS_REJECTED ? (
        <>
          <Title className="text-lg mb-2">La solicitud fue rechazada</Title>
          <Content>
            <span>Contacte a soporte si tiene alguna duda.</span>
          </Content>
        </>
      ) : null}

      <Modal
        type={modalConfig.type}
        title={modalConfig.title}
        description={modalConfig.description}
        confirmButtonText={modalConfig.confirmButtonText}
        confirmButtonCallback={confirmModalHandler}
        cancelButtonText={modalConfig.cancelButtonText}
        Icon={modalConfig.Icon}
        open={openModal}
        setOpen={setOpenModal}
      />
    </div>
  );
}

PatientReschedule.propTypes = {
  ticket: PropTypes.object.isRequired,
  refetchTicket: PropTypes.func.isRequired,
};
