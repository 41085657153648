import Title from 'components/Title';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

export default function UserContent({ user, title }) {
  return (
    <div>
      <Title className="font-bold font-header text-sm text-gray-500">{title}</Title>
      <div className="flex items-center">
        <p className="mr-1 text-gray-400">Nombre:</p>
        <p className="text-gray-600 font-bold">
          {user.first_name} {user.last_name}
        </p>
      </div>
      <div className="flex items-center">
        <p className="mr-1 text-gray-400">País:</p>
        <div className="flex items-center">
          <p className="mr-1 text-gray-600 font-bold">{user.address.state.country.name}</p>
          <ReactCountryFlag countryCode={user.address.state.country.code} />
        </div>
      </div>
      <div className="flex items-center">
        <p className="mr-1 text-gray-400">Zona Horaria:</p>
        <p className="text-gray-600 font-bold">{user.address.timezone.name}</p>
      </div>
    </div>
  );
}

UserContent.propTypes = {
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

UserContent.defaultProps = {};
