import {
  AcademicCapIcon,
  CalendarIcon,
  LocationMarkerIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import CompleteProfile from 'components/CompleteProfile';
import Subtitle from 'components/Subtitle';
import Tag from 'components/Tag';
import Title from 'components/Title';
// import QuoteCard from 'components/QuoteCard';
import moment from 'moment';
import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { SPANISH_MONTHS } from 'utils/constants';
import { PSYCHOLOGIST_DEGREES } from 'utils/psychologistConstants';

export default function ProfileDetail() {
  // Outlet context
  const psychologistProfileQuery = useOutletContext();

  const navigate = useNavigate();

  if (psychologistProfileQuery.isLoading) {
    return <BasicLayout title="Perfil">Cargando...</BasicLayout>;
  }
  const data = psychologistProfileQuery.data;
  const createdAt = moment.utc(data.created_at).local();
  const createdAtMonth = SPANISH_MONTHS[createdAt.toDate().getMonth()];
  const since = `${createdAtMonth} del ${createdAt.format('YYYY')}`;

  const buildTag = (tag, index) => (
    <div key={index} className="mr-2 mb-2">
      <Tag selected tag={tag.text} color={tag.color} />
    </div>
  );

  const buildTags = (tags = []) => {
    const occupationTags = tags.filter((tag) => tag.type === 0);
    const problematicsTags = tags.filter((tag) => tag.type === 1);

    return (
      <div>
        <Subtitle className="font-bold leading-6 text-tertiary">
          Te especializas en ayudar a:
        </Subtitle>
        <div className="my-2 flex flex-wrap">{occupationTags.map(buildTag)}</div>
        <Subtitle className="font-bold leading-6 text-tertiary">
          Los padecimientos que atiendes son:
        </Subtitle>
        <div className="mt-2 flex flex-wrap">{problematicsTags.map(buildTag)}</div>
      </div>
    );
  };

  const buildEducation = (education, index) => {
    return (
      <div key={index} className="px-6 py-8 my-2 border rounded-2xl shadow-sm hover:shadow-lg">
        <Title className="leading-6 text-sm text-gray-500 mb-2">{education.title}</Title>
        <div className="w-full flex ">
          <AcademicCapIcon className="h-6 w-6 text-primary mr-2" />
          <p className="font-bold leadind-6 text-gray-500">{education.institute}</p>
        </div>
        <div className="w-full flex ">
          <LocationMarkerIcon className="h-6 w-6 text-primary mr-2" />
          <p className="font-bold leadind-6 text-gray-500">{education.location}</p>
        </div>
        <div className="w-full flex items-center">
          <CalendarIcon className="h-6 w-6 text-primary mr-2" />
          <p className="leadind-6 text-gray-500">
            Desde <span className="font-bold ">{education.start_date}</span> hasta{' '}
            <span className="font-bold ">{education.end_date ? education.end_date : 'Ahora'}</span>
          </p>
        </div>
      </div>
    );
  };
  const buildExperience = (experience, index) => {
    return (
      <div key={index} className="px-6 py-8 my-2 border rounded-2xl shadow-sm hover:shadow-lg">
        <Title className="leading-6 text-sm text-gray-500 mb-2">{experience.title}</Title>
        <div className="w-full flex ">
          <OfficeBuildingIcon className="h-6 w-6 text-primary mr-2" />
          <p className="font-bold leadind-6 text-gray-500">
            {experience.charge} en {experience.company}
          </p>
        </div>
        <div className="w-full flex ">
          <LocationMarkerIcon className="h-6 w-6 text-primary mr-2" />
          <p className="font-bold leadind-6 text-gray-500">{experience.location}</p>
        </div>
        <div className="w-full flex items-center">
          <CalendarIcon className="h-6 w-6 text-primary mr-2" />
          <p className="leadind-6 text-gray-500">
            Desde <span className="font-bold ">{experience.start_date}</span> hasta{' '}
            <span className="font-bold ">
              {experience.end_date ? experience.end_date : 'Ahora'}
            </span>
          </p>
        </div>
      </div>
    );
  };
  const psychologistDegree = PSYCHOLOGIST_DEGREES[String(data.degree)];

  return (
    <BasicLayout title="Perfil">
      <div className="flex flex-col lg:flex-row box-border">
        <div className="flex-1 md:mr-4">
          <CompleteProfile
            completeName={`${data.first_name} ${data.last_name}`}
            tag={`${psychologistDegree.degree} / ${psychologistDegree.sessionCost} USD`}
            navigate={navigate}
            location={data.address.country.name}
            aboutMe={data.biography}
            image={data.photo_URL}
            sinceWhen={since}
            userRole={'Dover'}
          ></CompleteProfile>
          <br />
          <Subtitle className="font-bold leading-6 text-tertiary">Experiencia</Subtitle>
          {data.experiences.map(buildExperience)}
          <br />
          <Subtitle className="font-bold leading-6 text-tertiary">Educación</Subtitle>
          {data.educations.map(buildEducation)}
        </div>
        <div className="flex-1 md:ml-2 mt-4  md:mt-0">
          <div className="w-full mb-4">
            <iframe
              className="w-full h-80"
              src={data.video}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="px-6 py-8 mb-4 border rounded-2xl shadow-sm hover:shadow-lg">
            {buildTags(data.tags)}
            <Subtitle className="font-bold leading-6 text-tertiary mt-4">
              ¿Por qué estoy en Mindove?
            </Subtitle>
            <p className="leading-5 text-md text-gray-500">{data.reason}</p>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}
