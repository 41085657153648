import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

// const PSYCHOLOGIST_ID = 2;
const PSYCHOLOGISTS_PATH = 'psychologists/';
const PATH = API_URL + PSYCHOLOGISTS_PATH;

export const getPsychologistProfile = async (token) => {
  const { data } = await axios.get(`${PATH}detail`, {
    headers: headers(token),
  });
  return data;
};

export const getPsychologistSessions = async (id) => {
  const { data } = await axios.get(`${PATH + id}/sessions`);
  return data;
};
