import * as Yup from 'yup';

const requiredFieldMessage = 'Este campo es requerido.';

export const finalizeSchema = Yup.object().shape({
  tasks: Yup.array()
    .of(
      Yup.object().shape({
        content: Yup.string().required(requiredFieldMessage),
      }),
    )
    .min(1, 'Asigne al menos una tarea'),
});

export const RescheduleSchema = Yup.object().shape({
  description: Yup.string().required(requiredFieldMessage),
  selectedDate: Yup.date().typeError('Fecha invalida.').required(requiredFieldMessage),
});
