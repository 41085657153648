import { CalendarIcon, UserAddIcon, UserIcon } from '@heroicons/react/solid';
import ContactCard from 'components/ContactCard';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TICKET_REQUESTERS, TICKET_STATUSES } from 'utils/parsers/tickets';

export default function TicketCard({ ticket, index, callback }) {
  const patient = ticket.patient;
  const patientFullName = `${patient.first_name} ${patient.last_name}`;
  const parsedTicketStatus = TICKET_STATUSES[ticket.status];
  const parsedRequestedBy = TICKET_REQUESTERS[ticket.requested_by];
  const createdDate = moment.utc(ticket.created_at).local();
  const infoDescription = [
    {
      children: <p className={`font-body m-0 text-gray-500 text-xs`}>{patientFullName}</p>,
      icon: <UserIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
    },
    {
      children: (
        <p className={`font-body m-0 text-gray-500 text-xs`}>
          <strong>{createdDate.format('DD [de] MMMM [del] YYYY')}</strong>
        </p>
      ),
      icon: <CalendarIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
    },
    {
      children: (
        <p className={`font-body m-0 text-gray-500 text-xs`}>
          Solicitado por el/la{' '}
          <span className="font-bold text-gray-700">{parsedRequestedBy.tag}</span>
        </p>
      ),
      icon: <UserAddIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
    },
  ];
  return (
    <div className="my-4 border rounded-lg shadow-md" key={index}>
      <ContactCard
        title={ticket.title}
        buttonInfo={[
          {
            text: 'Detalles',
            icon: <UserIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
            onClickEvent: () => callback(ticket.id),
          },
        ]}
        infoDescription={infoDescription}
        tag={parsedTicketStatus.tag}
        tagColor={parsedTicketStatus.color}
        withDot
      >
        <img className="rounded-full h-10 w-10 object-cover" src={patient.photo_URL} alt="" />
      </ContactCard>
    </div>
  );
}

TicketCard.propTypes = {
  ticket: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
};
