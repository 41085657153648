import QuoteIcon from 'assets/icons/Quote';
import PropTypes from 'prop-types';
import React from 'react';

export default function QuoteCard({ firstText, secondText, thirdText, className }) {
  return (
    <div className={`flex justify-between items-center w-full p-6 z-10 rounded-2xl ${className}`}>
      <div className="w-full flex flex-col justify-between h-full relative">
        <div className="flex-1 w-full">
          <div className="z-10">
            <QuoteIcon />
          </div>
          <br />
          <p className=" text-white relative z-10 font-body z-20 font-bold">{firstText}</p>
        </div>
        <br />
        <br />
        <div className="flex-1 flex flex-col">
          <span className="text-white font-body text-sm font-bold">{secondText}</span>
          <span className="text-primary font-body text-sm font-bold">{thirdText}</span>
        </div>
      </div>
    </div>
  );
}

QuoteCard.propTypes = {
  firstText: PropTypes.string.isRequired,
  thirdText: PropTypes.string,
  secondText: PropTypes.string,
  className: PropTypes.string,
};

QuoteCard.defaultProps = {
  className: 'bg-secondary',
};
