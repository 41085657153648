import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import FormTemporaryCode from 'components/Forms/FormTemporaryCode';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Title from 'components/Title';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { LogInSchema } from 'schemas/login';
import { getTemporaryCode, getToken } from 'services/auth';
import { auth as authState } from 'state/auth';

import MindoveLogoWhite from '../../assets/icons/logotipo_white_transparent.png';

export default function Login() {
  // Global state
  const [, setAuth] = useRecoilState(authState);

  // Local state
  const [email, setEmail] = useState(null);

  // React Router
  const navigate = useNavigate();

  // Login RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError: setLoginError,
  } = useForm({
    defaultValues: {
      remember: false,
    },
    resolver: yupResolver(LogInSchema),
  });

  // Temporary code RGF
  const {
    register: codeRegister,
    handleSubmit: handleCodeSubmit,
    setError: setTemporaryCodeError,
    formState: { errors: temporaryCodeErrors },
  } = useForm({});

  /**
   * RESPONSE REACTIONS:
   * - onErrorTemporaryCode
   * - onSuccessGetToken
   * - onErrorGetToken
   */
  const onErrorTemporaryCode = ({ response: { data } }) => {
    setLoginError('email', { message: data.email });
  };

  const onSuccessGetToken = (data) => {
    setAuth({ token: data.token, user: data.user });
    navigate('/ic');
  };

  const onErrorGetToken = (data) => {
    const errors = data.response?.data;
    const message = errors?.code || errors?.error;
    setTemporaryCodeError('digit_0', { message });
  };

  // React Query
  const temporaryCodeMutation = useMutation((data) => getTemporaryCode(data.email), {
    onError: onErrorTemporaryCode,
  });

  const tokenMutation = useMutation((data) => getToken(data.code, data.email), {
    onSuccess: onSuccessGetToken,
    onError: onErrorGetToken,
  });

  // Submit actions
  const onSubmit = ({ email }) => {
    setEmail(email);
    temporaryCodeMutation.mutate({ email });
  };

  const onSubmitCode = (data) => {
    const code = Object.values(data).join('');
    tokenMutation.mutate({ code, email });
  };

  const temporaryCodeError = temporaryCodeErrors?.temporary_code?.message;

  return (
    <div className="min-h-full flex justify-center items-center bg-primary text-center">
      <div className="px-0 md:px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="flex flex-col flex-1 md:flex-none mx-auto w-full max-w-md md:max-w-md lg:w-96">
          <div className="pb-4 md:pb-0 px-4 md:px-0 box-border">
            <div>
              <img alt="Mindove" src={MindoveLogoWhite} />
            </div>
            <Title className="mt-6 text-white ">Ingresa a tu cuenta</Title>
          </div>

          <div className="px-4 md:px-0 pt-4 md:pt-0 rounded-t-2xl ">
            <div className="flex-1 mt-6">
              {temporaryCodeMutation.isSuccess ? (
                <>
                  <p className="leading-6 text-white mb-4">
                    Consulta tu Email <span className="font-bold">{email}</span> para obtener tu
                    código de acceso único
                  </p>
                  <FormTemporaryCode
                    handleSubmit={handleCodeSubmit}
                    onSubmit={onSubmitCode}
                    register={codeRegister}
                    mutation={tokenMutation}
                    error={temporaryCodeError}
                  />
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-6">
                    <Input
                      id="email"
                      type="email"
                      labelClasses="text-white"
                      register={register}
                      registerConfig={{}}
                      withLabel
                      textLabel="Email"
                      autocomplete="email"
                      error={errors.email?.message}
                      disabled={temporaryCodeMutation.isLoading}
                    />

                    <div>
                      <Button
                        disabled={temporaryCodeMutation.isLoading}
                        type="submit"
                        className="btn-tertiary rounded-md"
                      >
                        {temporaryCodeMutation.isLoading ? (
                          <>
                            <Loader className="h-6 w-6 text-white" /> Procesando...
                          </>
                        ) : (
                          'Generar código'
                        )}
                      </Button>
                    </div>
                  </form>
                  <br />
                  <br />
                  <br />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
