import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

/**
 * Get all patient information.
 * This state is set in List page.
 * source: src/pages/Patients/List/index
 */

const { persistAtom } = recoilPersist();

export const patient = atom({
  key: 'patient',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

/**
 * Get request from patient information.
 * This state is set in pages/patients/detail/profile
 * It will be mostly used to use the request id in accompaniment page.
 */

export const request = atom({
  key: 'request',
  default: '',
});
