import PropTypes from 'prop-types';
import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

export default function NavItem({ name, href, Icon, classNames, IconClassName }) {
  let resolved = useResolvedPath(href);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      key={name}
      to={href}
      className={classNames(
        match ? 'bg-secondary text-white' : 'text-white hover:bg-secondary hover:text-white',
        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
      )}
    >
      <Icon className={IconClassName} aria-hidden="true" />
      {name}
    </Link>
  );
}

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  classNames: PropTypes.func.isRequired,
  IconClassName: PropTypes.string.isRequired,
  Icon: PropTypes.any,
  onClick: PropTypes.func,
};
