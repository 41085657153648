// User status
export const USER_STATUS_CREATED = 0;
export const USER_STATUS_NO_ACTIVITIES = 1;
export const USER_STATUS_IN_ACCOMPANIMENT_REQUEST = 2;
export const USER_STATUS_IN_ACCOMPANIMENT = 3;

// Accompaniment status
export const ACCOMPANIMENT_CREATED = 0;
export const ACCOMPANIMENT_ACCEPTED = 1;
export const ACCOMPANIMENT_IN_PROGRESS = 2;
export const ACCOMPANIMENT_STOPPED_NO_PAYMENT = 3;
export const ACCOMPANIMENT_STOPPED_BY_PATIENT = 4;
export const ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST = 5;
export const ACCOMPANIMENT_CANCELED_NO_PAYMENT = 6;
export const ACCOMPANIMENT_CANCELED_BY_PATIENT = 7;
export const ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST = 8;
export const ACCOMPANIMENT_CANCELED_BY_ADMIN = 9;
export const ACCOMPANIMENT_FINALIZED = 10;

// Accompaniment request status
export const ACCOMPANIMENT_REQUEST_STATUS_CREATED = 0;
export const ACCOMPANIMENT_REQUEST_STATUS_IN_PROGRESS = 1;
export const ACCOMPANIMENT_REQUEST_STATUS_IN_REVIEW = 2;
export const ACCOMPANIMENT_REQUEST_STATUS_ACCEPTED = 3;
export const ACCOMPANIMENT_REQUEST_STATUS_REJECTED_BY_PSY = 4;
export const ACCOMPANIMENT_REQUEST_STATUS_REJECTED_BY_PAT = 5;
export const ACCOMPANIMENT_REQUEST_STATUS_FINALIZED = 5;

// Autodiagnosis status
export const AUTODIAGNOSIS_CREATED = 0;
export const AUTODIAGNOSIS_IN_PROGRESS = 1;
export const AUTODIAGNOSIS_COMPLETED = 2;

// Session status
export const SESSION_CREATED = 0;
export const SESSION_SCHEDULED = 1;
export const SESSION_PAYED = 2;
export const SESSION_UNPAYED = 3;
export const SESSION_CANCELED = 4;
export const SESSION_IN_PROGRESS = 5;
export const SESSION_TIME_COMPLETED = 6;
export const SESSION_FINALIZED = 7;
