import Content from 'components/Content';
import Loader from 'components/Loader';
import ReadQuestionaryMobile from 'components/ReadQuestionaryMobile';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getAccompanimentRequestByPsychologist } from 'services/patients';
import { getToken } from 'state/auth';
import { patient as patientAtom } from 'state/patient';

export default function PatientAutodiagnosis() {
  // Global State
  const token = useRecoilValue(getToken);
  const patient = useRecoilValue(patientAtom);

  const accompaniment = useOutletContext();
  console.log('🚀 ~ file: index.js:17 ~ PatientAutodiagnosis ~ outletContext:', accompaniment);

  const autodiagnosis = accompaniment.autodiagnosis;

  const { data, status } = useQuery(
    ['patientAutodiagnosis', token, patient.id],
    () => getAccompanimentRequestByPsychologist(token, patient.id),
    {
      enabled: !!autodiagnosis,
    },
  );

  return (
    <div className="w-full grid">
      {autodiagnosis ? (
        <p>NOMAS POR MIENTRAS</p>
      ) : (
        <div>
          <Content className="text-content">Proximamente.</Content>
        </div>
      )}

      {/* {status == 'success' && <ReadQuestionaryMobile autodiagnosis={data.autodiagnosis} />}
      {status == 'loading' && (
        <>
          <Loader /> Procesando...
        </>
      )} */}
    </div>
  );
}
