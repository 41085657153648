import { RadioGroup } from '@headlessui/react';
import PropTypes from 'prop-types';
import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CustomRadioGroupOption({ index, option, children }) {
  return (
    <RadioGroup.Option
      key={index}
      value={option}
      className={({ checked, active }) =>
        classNames(
          checked ? 'border-transparent' : 'border-gray-300',
          active ? 'ring-2 ring-indigo-500' : '',
          'relative block bg-white border rounded-lg shadow-sm px-6 py-2 md:py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none',
        )
      }
    >
      {({ active, checked }) => (
        <>
          <div className="flex items-center w-full">
            <div className="text-sm w-full ">
              <RadioGroup.Label as="p" className="font-bold text-primary">
                <span>Opcion {index + 1} </span>
              </RadioGroup.Label>

              <RadioGroup.Description as="div" className="text-gray-500 w-full mt-2">
                {children}
              </RadioGroup.Description>
            </div>
          </div>

          <div
            className={classNames(
              active ? 'border' : 'border-2',
              checked ? 'border-indigo-500' : 'border-transparent',
              'absolute -inset-px rounded-lg pointer-events-none',
            )}
            aria-hidden="true"
          />
        </>
      )}
    </RadioGroup.Option>
  );
}

CustomRadioGroupOption.propTypes = {
  index: PropTypes.number.isRequired,
  option: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};
