import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// ID only
export const selectedTicket = atom({
  key: 'selectedTicket',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
