import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

// TODO: change everything to axios
// TODO: changes paths so all the paths are the same on all services

const PATH = API_URL + 'accompaniments/';

export const getAccompanimentDetail = async (token, accompanimentID) => {
  const { data } = await axios.get(`${PATH}${accompanimentID}`, {
    headers: headers(token),
  });

  return data;
};

export const getAccompaniment = async (token) => {
  const { data } = await axios.get(`${PATH}detail`, {
    headers: headers(token),
  });

  return data;
};

export const getAccompanimentSessions = async (id, token) => {
  const { data } = await axios.get(`${PATH}${id}/sessions`, {
    headers: headers(token),
  });

  return data;
};

export const getAccompanimentTasks = async (id, token) => {
  const { data } = await axios.get(`${PATH}${id}/tasks`, {
    headers: headers(token),
  });
  return data;
};

export const listAccompanimentsByPsychologist = async (token, filters) => {
  const { data } = await axios.get(`${PATH}psychologist/filters/`, {
    headers: headers(token),
    params: {
      statuses: filters.status,
    },
  });
  return data;
};

export const createNextSession = async (token, body) => {
  const { data } = await axios.post(`${PATH}next-session/`, body, {
    headers: headers(token),
  });
  return data;
};

export const retrieveDailyPhrase = async (token) => {
  const rndInt = Math.floor(Math.random() * 340) + 1;
  const { data } = await axios.get(`${API_URL}daily-phrase/${rndInt}/`, {
    headers: headers(token),
  });

  return data;
};
