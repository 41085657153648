import axios from 'axios';
import { API_URL } from 'utils/constants';

const AUTH_PATH = 'auth/';

export const getTemporaryCode = async (email) => {
  const { data } = await axios.post(`${API_URL}${AUTH_PATH}login/psy/`, {
    email,
  });

  return data;
};

export const getToken = async (code, email) => {
  const { data } = await axios.post(`${API_URL}${AUTH_PATH}token/`, {
    code,
    email,
  });

  return data.data;
};
