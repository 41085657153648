import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const PATH = API_URL + 'patients/';

export const getAccompanimentRequestByPsychologist = async (token, id) => {
  const { data } = await axios.get(`${PATH + id}/request/`, {
    headers: headers(token),
  });
  return data;
};
