const {
  CalendarIcon,
  XCircleIcon,
  GlobeAltIcon,
  XIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  PauseIcon,
  CheckCircleIcon,
  ClockIcon,
} = require('@heroicons/react/solid');
const {
  TYPE_RESCHEDULE_SESSION,
  TYPE_CANCEL_SESSION,
  TYPE_CHANGE_ACCOMPANIMENT_PERIODICITY,
  TYPE_CANCEL_ACCOMPANIMENT,
  TYPE_REDUCE_ACCOMPANIMENT,
  TYPE_EXTEND_ACCOMPANIMENT,
  TYPE_STOP_ACCOMPANIMENT,
  STATUS_IN_REVIEW,
  STATUS_APPROVED,
  STATUS_REJECTED,
} = require('utils/constants/tickets');

export const TICKET_TYPES_MENU = [
  {
    name: 'Reagendar sesión',
    icon: CalendarIcon,
    type: TYPE_RESCHEDULE_SESSION,
  },
  {
    name: 'Cancelar sesión',
    icon: XCircleIcon,
    type: TYPE_CANCEL_SESSION,
  },
  {
    name: 'Cambio de periodicidad de acompañamiento',
    icon: GlobeAltIcon,
    type: TYPE_CHANGE_ACCOMPANIMENT_PERIODICITY,
  },
  {
    name: 'Cancelar Acompañamiento',
    icon: XIcon,
    type: TYPE_CANCEL_ACCOMPANIMENT,
  },
  {
    name: 'Reducir Acompañamiento',
    icon: MinusCircleIcon,
    type: TYPE_REDUCE_ACCOMPANIMENT,
  },
  {
    name: 'Extender Acompañamiento',
    icon: PlusCircleIcon,
    type: TYPE_EXTEND_ACCOMPANIMENT,
  },
  {
    name: 'Pausar Acompañamiento',
    icon: PauseIcon,
    type: TYPE_STOP_ACCOMPANIMENT,
  },
];

export const TICKET_STATUS_MENU = [
  {
    name: 'En revisión',
    icon: ClockIcon,
    status: STATUS_IN_REVIEW,
  },
  {
    name: 'Aprobados',
    icon: CheckCircleIcon,
    status: STATUS_APPROVED,
  },
  {
    name: 'Rechazados',
    icon: XCircleIcon,
    status: STATUS_REJECTED,
  },
];
