import {
  BanIcon,
  CalendarIcon,
  CheckIcon,
  ClockIcon,
  LocationMarkerIcon,
  PauseIcon,
  UserIcon,
} from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import ContactCard from 'components/ContactCard';
import Loader from 'components/Loader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listAccompanimentsByPsychologist } from 'services/accompaniment';
import { accompaniment as accompanimentAtom } from 'state/accompaniment';
import { getToken } from 'state/auth';
import { patient as patientAtom } from 'state/patient';
import { SPANISH_MONTHS } from 'utils/constants';
import { ACCOMPANIMENT_STATUS } from 'utils/patient';

const tabs = [
  {
    name: 'En progreso',
    icon: ClockIcon,
    filters: { status: '2' },
  },
  {
    name: 'Pausados',
    icon: PauseIcon,
    filters: { status: '3,4,5' },
  },
  {
    name: 'Cancelados',
    icon: BanIcon,
    filters: { status: '6,7,8,9' },
  },
  {
    name: 'Finalizado',
    icon: CheckIcon,
    filters: { status: '10' },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AccompanimentList() {
  // Global states
  const token = useRecoilValue(getToken);
  const [, setPatient] = useRecoilState(patientAtom);
  const [, setAccompaniment] = useRecoilState(accompanimentAtom);

  // LOCAL STATES
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState(tabs[0].filters);

  // RRD
  const navigate = useNavigate();

  // REACT QUERY
  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['listAccompanimentsByPsychologist', token, filters],
    () => listAccompanimentsByPsychologist(token, filters),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    const selectedTab = tabs[`${activeTab}`];
    setFilters(selectedTab.filters);
  }, [activeTab]);

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  if (isLoading) {
    return (
      <BasicLayout title="Acompañamientos">
        <div className="w-full flex justify-center items-center">
          <Loader className="h-12 w-12 text-primary" />
        </div>
      </BasicLayout>
    );
  }

  const onClickHandler = (data) => {
    console.log('🚀 ~ onClickHandler ~ data:', data);
    const patient = data.patient;
    const accompaniment = {
      id: data.id,
      progress: data.progress,
      status: data.status,
    };

    setPatient(patient);
    setAccompaniment(accompaniment);
    navigate(`detail`);
    return null;
  };

  const buildCard = (accompaniment, index) => {
    const patient = accompaniment.patient;
    const createdDate = moment.utc(patient.created_at).local().toDate();
    const infoDescription = [
      {
        children: (
          <p className={`font-body m-0 text-gray-500 text-xs`}>
            {patient.address.state.name}, {patient.address.state.country.name}
          </p>
        ),
        icon: <LocationMarkerIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
      },
      {
        children: (
          <p className={`font-body m-0 text-gray-500 text-xs`}>
            Paciente desde <strong>{SPANISH_MONTHS[createdDate.getMonth()]}</strong>
          </p>
        ),
        icon: <CalendarIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
      },
    ];
    return (
      <div className="my-4 border rounded-lg shadow-md" key={index}>
        <ContactCard
          title={patient.first_name + ' ' + patient.last_name}
          buttonInfo={[
            {
              text: 'Detalles',
              icon: <UserIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
              onClickEvent: () => onClickHandler(accompaniment),
            },
          ]}
          infoDescription={infoDescription}
          tag={ACCOMPANIMENT_STATUS[accompaniment.status].tag}
          navigate={navigate}
          tagColor={ACCOMPANIMENT_STATUS[accompaniment.status].tagColor}
          withDot={ACCOMPANIMENT_STATUS[accompaniment.status].withDot}
        >
          <img className="rounded-full h-10 w-10" src={patient.photo_URL} alt="" />
        </ContactCard>
      </div>
    );
  };

  if (isSuccess) {
    return (
      <BasicLayout title="Acompañamientos">
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              defaultValue={tabs[`${activeTab}`].name}
              onChange={(e) => setActiveTab(Number(e.target.value))}
            >
              {tabs.map((tab, index) => (
                <option key={tab.name} value={index}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab, index) => (
                  <button
                    key={tab.name}
                    onClick={() => setActiveTab(index)}
                    className={classNames(
                      activeTab === index
                        ? 'border-primary text-primary'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'group inline-flex items-center py-4 px-1 border-b-2 text-content',
                    )}
                    aria-current={activeTab === index ? 'page' : undefined}
                  >
                    <tab.icon
                      className={classNames(
                        activeTab === index
                          ? 'text-primary'
                          : 'text-gray-400 group-hover:text-gray-500',
                        '-ml-0.5 mr-2 h-5 w-5',
                      )}
                      aria-hidden="true"
                    />
                    <span>{tab.name}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:grid grid-cols-2 auto-rows-fr gap-x-3 box-border">
          {data.length ? (
            data.map(buildCard)
          ) : (
            <p className="mt-4 leading-5 text-gray-500 text-content">
              Aun no tiene solicitudes con este estatus
            </p>
          )}
        </div>
      </BasicLayout>
    );
  }

  return (
    <BasicLayout title="Pacientes">
      <Loader className="text-primary" />
    </BasicLayout>
  );
}
