import PropTypes from 'prop-types';
import React from 'react';

export default function Error({ children }) {
  return <div>{children}</div>;
}

Error.propTypes = {
  children: PropTypes.any.isRequired,
};
