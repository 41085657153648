import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import React from 'react';

export default function FormTemporaryCode({ handleSubmit, onSubmit, register, mutation, error }) {
  const handleChange = ({ target: { id, value } }) => {
    const nextInputKey = Number(id.split('_')[1]) + 1;
    const nextElement = document.getElementById(`digit_${nextInputKey}`);
    if (nextElement !== null && value) {
      nextElement.focus();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-6">
      <div className="flex flex-row justify-between">
        <div className="w-full">
          <Input
            id={`temporary_code`}
            textPosition={'tracking-[2em] text-center'}
            register={register}
            registerConfig={{}}
            handleChange={handleChange}
            disabled={mutation.isLoading}
            error={error}
          />
        </div>
      </div>
      {error && <p className="text-sm font-body font-medium text-pink-500">{error}</p>}
      <div>
        <Button disabled={mutation.isLoading} type="submit" className="btn-tertiary rounded-md">
          {mutation.isLoading ? (
            <>
              <Loader className="text-white h-6 w-6" /> Procesando...
            </>
          ) : (
            'Ingresar'
          )}
        </Button>
      </div>
    </form>
  );
}

FormTemporaryCode.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  mutation: PropTypes.object.isRequired,
  error: PropTypes.string,
};
