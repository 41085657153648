// import 'react-datepicker/dist/react-datepicker.css';

import { TrashIcon } from '@heroicons/react/outline';
import { CalendarIcon, PlusCircleIcon } from '@heroicons/react/solid';
import Alert from 'components/Alert';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Content from 'components/Content';
import Title from 'components/Title';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { bulkInsertAvailabilitiesService, listAgenda } from 'services/availability';
import { auth as authState, getToken } from 'state/auth';
import { capitalize } from 'utils/commons';

import Availability from './components/Availability';
import Day from './components/Day';

const localizeDate = (date) => {
  return moment.utc(date).local();
};

export default function ListAvailability() {
  // Global state
  const token = useRecoilValue(getToken);
  const [auth] = useRecoilState(authState);

  // Local state
  const userFriendlyFormat = 'dddd D [de] MMMM [del] YYYY';

  const start = moment().startOf('day').add(1, 'day');
  const end = moment().endOf('day').add(10, 'day');

  const today = start.format('YYYY-MM-DD');
  const todayEnd = end.format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(todayEnd);

  // RRD
  const navigate = useNavigate();

  // REACT QUERY
  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['listAgenda', token, auth?.user?.id, startDate, endDate],
    () => listAgenda(token, auth?.user?.id, startDate, endDate),
  );

  const availabilitiesGroupedByDay = data
    ? data.reduce((result, availability) => {
        const startTimeLocal = localizeDate(availability.start_time);
        const endTimeLocal = localizeDate(availability.end_time);
        const parsedAvailability = {
          id: availability.id,
          date: startTimeLocal.format('L'),
          originalStartTime: availability.start_time,
          originalEndTime: availability.end_time,
          parsedDate: startTimeLocal.format(userFriendlyFormat),
          parsedStartTime: startTimeLocal.format('h:mm a'),
          parsedEndTime: endTimeLocal.format('h:mm a'),
        };

        const day = parsedAvailability.date;
        if (!result[String(day)]) {
          result[String(day)] = [];
        }

        result[String(day)].push(parsedAvailability);
        return result;
      }, {})
    : {};

  // RHF
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      newAvailability: [
        {
          start_date: moment()
            .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
            .add(1, 'd')
            .toDate(),
          end_date: moment()
            .set({ hour: 13, minute: 0, second: 0, millisecond: 0 })
            .add(1, 'd')
            .toDate(),
        },
      ],
    },
    // resolver: yupResolver(ActionRescheduleSchema),
  });

  const newAvailability = watch('newAvailability');

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'newAvailability', // unique name for your Field Array
  });

  const bulkInsertAvailabilitiesMutation = useMutation(
    (data) => bulkInsertAvailabilitiesService(token, data),
    {
      onSuccess: () => {
        reset();
        toast.success('Se agregaron las disponibilidades con exito.');
      },
      onError: (error) => {
        console.log('🚀 ~ file: index.js:62 ~ error:', error);
        toast.error('Falló la carga de disponibilidades.');
      },
    },
  );

  const onSubmit = (data) => {
    bulkInsertAvailabilitiesMutation.mutate(data.newAvailability);
  };

  const buildAvailability = (availability) => (
    <Availability
      key={`availability-${availability.id}`}
      startTime={availability.parsedStartTime}
      endTime={availability.parsedEndTime}
    />
  );

  const buildAgenda = (days) => {
    const agenda = [];
    for (let day in days) {
      const parsedDate = capitalize(days[String(day)][0].parsedDate);
      const availabilities = days[String(day)];

      const dayUI = (
        <Day
          key={`availability-${day}`}
          day={parsedDate}
          availabilities={availabilities}
          buildAvailability={buildAvailability}
        />
      );

      agenda.push(dayUI);
    }
    return <div className="grid grid-cols-1 gap-4">{agenda.reverse()}</div>;
  };

  return (
    <BasicLayout title="Create Availability">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
        <div className="mt-8 lg:mt-0">
          <Title className="text-title">Agenda</Title>
          <div className="mt-2 grid gap-y-8">
            {Object.keys(availabilitiesGroupedByDay).length ? (
              buildAgenda(availabilitiesGroupedByDay)
            ) : (
              <>
                <Alert
                  type={'warning'}
                  text={
                    <Content>
                      {' '}
                      No tienes disponibilidades creadas en los proximos 10 dias, el paciente NO
                      verá disponibilidades visibles en tu perfil.
                    </Content>
                  }
                  showActionIcon={false}
                  Icon={CalendarIcon}
                  // ActionIcon={ArrowRightIcon}
                  // actionCallback={alertCallback}
                />
              </>
            )}
          </div>
        </div>
        <div className="">
          <Title className="text-title">Creación de disponibilidades</Title>
          <br />
          <form onSubmit={handleSubmit(onSubmit)} method="POST" className="w-full space-y-6">
            {fields.map((field, index) => {
              return (
                <div key={field.id} className="w-full">
                  <div className="w-full grid grid-cols-2 mb-4">
                    <div className="text-subtitle">
                      <Content>Disponibilidad {index + 1}</Content>
                    </div>
                    <div className="grid justify-end">
                      {index !== 0 ? (
                        <button onClick={() => remove(index)}>
                          <TrashIcon className={`h-5 w-5 ml-1 mt-px text-red-500`} />
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <Controller
                    control={control}
                    name={`newAvailability.${index}`}
                    render={(_props) => {
                      return (
                        <>
                          <DatePicker
                            className="w-full rounded-md border border-primary"
                            selected={_props.field.value.start_date}
                            onChange={(date) => {
                              _props.field.onChange({
                                start_date: date,
                                end_date: moment(date).add(1, 'hour').toDate(),
                              });
                            }}
                            showTimeSelect
                            timeIntervals={30}
                            minDate={moment().add(1, 'd').toDate()}
                            dateFormat="dd/MM/yyyy hh:mm aa"
                          />
                          {errors?.options?.[String(index)]?.message ? (
                            <p className="text-red-500 text-sm font-default">
                              {errors.options[String(index)].message}
                            </p>
                          ) : null}
                        </>
                      );
                    }}
                  />
                </div>
              );
            })}

            <div className="grid grid-cols-3">
              <div className="col-start-3">
                <Button
                  type="button"
                  className="btn-secondary"
                  icon={<PlusCircleIcon className="h-5 w-5 text-white" />}
                  onClick={() =>
                    append({
                      start_date: moment()
                        .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
                        .add(1, 'd')
                        .toDate(),
                      end_date: moment()
                        .set({ hour: 13, minute: 0, second: 0, millisecond: 0 })
                        .add(1, 'd')
                        .toDate(),
                    })
                  }
                />
              </div>
            </div>

            <Button type="submit" className="btn-primary">
              Crear disponibilidad
            </Button>
          </form>
        </div>
      </div>
    </BasicLayout>
  );
}
