import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const accompaniment = atom({
  key: 'accompaniment',
  default: {
    id: null,
    progress: null,
    status: null,
  },
  effects_UNSTABLE: [persistAtom],
});
