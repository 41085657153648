import PropTypes from 'prop-types';
import React from 'react';

export default function TaskItem({ text, title, date, icon }) {
  return (
    <div className="flex items-start">
      {icon}
      <div className="ml-2">
        <label htmlFor="email" className="block text-xs font-body font-bold text-black">
          {title}
        </label>
        <span className="text-xs m-0 font-body text-gray-600">{date}</span>
        <p className="text-xs m-0 font-body text-gray-400">{text}</p>
      </div>
    </div>
  );
}

TaskItem.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.any,
  icon: PropTypes.any,
};

TaskItem.defaultProps = {};
