import { ClipboardListIcon } from '@heroicons/react/outline';
import { CalendarIcon, ChatIcon, ClipboardCheckIcon, EyeIcon } from '@heroicons/react/solid';

export const ACCOMPANIMENT_DETAIL_MENU = [
  {
    name: 'Detalle',
    icon: EyeIcon,
    path: '.',
  },
  {
    name: 'Sala de chat',
    icon: ChatIcon,
    path: 'cometchat',
  },
  {
    name: 'Sesiones',
    icon: CalendarIcon,
    path: 'sessions',
  },
  {
    name: 'Autodiagnóstico',
    icon: ClipboardCheckIcon,
    path: 'autodiagnosis',
  },
  // TODO: DEPRECATED
  // {
  //   name: 'Tareas',
  //   icon: ClipboardIcon,
  //   path: 'tasks',
  // },
  // {
  //   name: 'Acciones',
  //   icon: ClipboardListIcon,
  //   path: 'actions',
  // },
];
