import { atom, selector } from 'recoil';

/**
 * - onCallEnded is for the states that we are gonna use when the call in Cometchat ends
 */
export const onCallEnded = atom({
  key: 'renderReview',
  default: {
    renderReview: false,
    renderTask: false,
  },
});

/**
 * - getRenderReview is to render Review after call
 */
export const getRenderReview = selector({
  key: 'getRenderReview',
  get: ({ get }) => get(onCallEnded).renderReview,
});

/**
 * - getTaskReview is to render Task after call
 */
export const getTaskReview = selector({
  key: 'getTaskReview',
  get: ({ get }) => get(onCallEnded).renderTask,
});

export const showCallControls = atom({
  key: 'showCallControls',
  default: false,
});
