import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import FormHeader from 'components/FormHeader';
import Input from 'components/Input';
import Subtitle from 'components/Subtitle';
import TextArea from 'components/TextArea';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { ProfilePsychologistSchema } from 'schemas/profilePsychologist';

export default function CompleteProfile() {
  // Outlet context
  const psychologistProfileQuery = useOutletContext();

  // Constants
  const { data } = psychologistProfileQuery;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: data.first_name,
      lastName: data.last_name,
      biography: data.biography,
      email: data.email,
      homePhone: data.home_phone,
      cellPhone: data.cell_phone,
      address: {
        country: data.address.country.name,
        state: data.address.state.name,
        timezone: data.address.timezone.name,
        city: data.address.city,
      },
    },
    resolver: yupResolver(ProfilePsychologistSchema),
  });

  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <BasicLayout title="Perfil">
      <Subtitle className="text-sm text-primary">
        Para cambiar la información de su perfil, por favor contacte a un administrador.
      </Subtitle>
      <br />
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <FormHeader
              title="Información básica"
              description="La información de perfil será visible para todos en Mindove"
            />
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow-lg rounded-lg overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <TextArea
                    id="biography"
                    description="biography"
                    register={register}
                    placeholder="Acerca de mí"
                    registerConfig={{ required: true }}
                    disabled={true}
                    error={errors.biography?.message}
                    textLabel="¿Por qué estoy en Mindove?"
                    labelClassName="text-sm leading-5 text-gray-700"
                    rows="4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <FormHeader title="Información Personal" />
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow-lg rounded-lg overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        id="firstName"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        textLabel="Nombre"
                        disabled={true}
                        error={errors.firstName?.message}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        id="lastName"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={true}
                        textLabel="Apellidos"
                        error={errors.lastName?.message}
                      />
                    </div>
                    {/* TODO: Add country select */}
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        id="address.city"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={true}
                        textLabel="Ciudad"
                        error={errors.address?.city?.message}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        id="address.country"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={true}
                        textLabel="Ciudad"
                        error={errors.address?.country?.message}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        id="address.state"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={true}
                        textLabel="Estado/Provincia"
                        error={errors.address?.statte?.message}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <FormHeader title="Información de Contacto" />
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow-lg rounded-lg overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        id="email"
                        type="email"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={true}
                        textLabel="Email"
                        error={errors.email?.message}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        id="homePhone"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={true}
                        textLabel="Teléfono"
                        error={errors.homePhone?.message}
                      />
                    </div>
                    {/* TODO: Add country select */}
                    <div className="col-span-6 sm:col-span-3">
                      <Input
                        id="cellPhone"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={true}
                        textLabel="Celular"
                        error={errors.cellPhone?.message}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BasicLayout>
  );
}
