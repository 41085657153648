import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

import TimezonedNextSessionSchedule from '../../../../components/TimezonedNextSessionSchedule.jsx';

export default function UserContent({ user, title, startDateUTC, endDateUTC }) {
  return (
    <div>
      <p className="font-bold font-header text-lg text-gray-500">{title}</p>
      <div className="flex items-center">
        <p className="mr-1 text-gray-400">Nombre:</p>
        <p className="text-gray-600 font-bold">{user.full_name}</p>
      </div>
      <div className="flex items-center">
        <p className="mr-1 text-gray-400">País:</p>
        <div className="flex items-center">
          {/* <p className="mr-1 text-gray-600 font-bold">{user.country.name}</p> */}
          <ReactCountryFlag countryCode={user.country_code} />
        </div>
      </div>
      <div className="flex items-center">
        <p className="mr-1 text-gray-400">Zona Horaria:</p>
        <p className="text-gray-600 font-bold">{user.timezone}</p>
      </div>
      <br />
      <TimezonedNextSessionSchedule
        startDateUTC={startDateUTC}
        endDateUTC={endDateUTC}
        timezone={user.timezone}
      />
    </div>
  );
}

UserContent.propTypes = {
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  startDateUTC: PropTypes.object.isRequired,
  endDateUTC: PropTypes.object.isRequired,
};

UserContent.defaultProps = {};
