import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const PATH = API_URL + 'calendar/availabilities/';

export const bulkInsertAvailabilitiesService = async (token, body) => {
  const { data } = await axios.post(`${PATH}bulk-insert/`, body, {
    headers: headers(token),
  });
  return data;
};

export const listAgenda = async (token, psychologistID, startDate, endDate) => {
  const { data } = await axios.get(`${PATH}`, {
    headers: headers(token),
    params: {
      calendar__psychologist_id: psychologistID,
      start_time: startDate,
      end_time: endDate,
    },
  });
  return data;
};
