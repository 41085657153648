import { ArrowRightIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon, ClipboardIcon, UserAddIcon, UserIcon } from '@heroicons/react/solid';
import BasicCard from 'components/BasicCard';
import BasicLayout from 'components/BasicLayout';
import DetailItem from 'components/DetailItem';
import Loader from 'components/Loader';
import Subtitle from 'components/Subtitle';
import Tag from 'components/Tag';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { retrieveDetail as retrieveTicketDetail } from 'services/tickets';
import { accompaniment as accompanimentState } from 'state/accompaniment';
import { getToken } from 'state/auth';
import { selectedSession as selectedSessionState } from 'state/session';
import { selectedTicket as selectedTicketState } from 'state/ticket';
import {
  REQUESTER_PATIENT,
  REQUESTER_PSYCHOLOGIST,
  TYPE_CANCEL_ACCOMPANIMENT,
  TYPE_EXTEND_ACCOMPANIMENT,
  TYPE_RESCHEDULE_SESSION,
} from 'utils/constants/tickets';
import { TICKET_REQUESTERS, TICKET_STATUSES } from 'utils/parsers/tickets';

import PatientReschedule from './Cases/PatientReschedule';
import PsychologistCancel from './Cases/PsychologistCancel';
import PsychologistExtend from './Cases/PsychologistExtend';

const TICKET_CASES = {
  [TYPE_RESCHEDULE_SESSION]: {
    [REQUESTER_PATIENT]: PatientReschedule,
    [REQUESTER_PSYCHOLOGIST]: 'HOLI',
  },
  [TYPE_EXTEND_ACCOMPANIMENT]: {
    [REQUESTER_PATIENT]: 'HOLI',
    [REQUESTER_PSYCHOLOGIST]: PsychologistExtend,
  },
  [TYPE_CANCEL_ACCOMPANIMENT]: {
    [REQUESTER_PATIENT]: 'HOLI',
    [REQUESTER_PSYCHOLOGIST]: PsychologistCancel,
  },
};

export default function TicketDetail() {
  // Global state
  const token = useRecoilValue(getToken);
  const [selectedTicket] = useRecoilState(selectedTicketState);
  const [, setAccompaniment] = useRecoilState(accompanimentState);
  const [, setSelectedSession] = useRecoilState(selectedSessionState);

  // RRD
  const navigate = useNavigate();

  // RQ
  const getTicketDetailQuery = useQuery(
    ['sessionDetail', token, selectedTicket],
    () => retrieveTicketDetail(token, selectedTicket),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const accompanimentCallback = (accompanimentID) => {
    const accompaniment = {
      id: accompanimentID,
    };
    setAccompaniment(accompaniment);
    navigate('/ic/accompaniments/detail');
  };

  const sessionCallback = (sessionID) => {
    setSelectedSession(sessionID);
    navigate('/ic/sessions/detail');
  };

  if (getTicketDetailQuery.isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex justify-center items-center h-full">
        <Loader className="h-12 w-12 text-primary" />
      </div>
    );
  }
  if (getTicketDetailQuery.isError) {
    return <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">Algo salió mal</div>;
  }

  if (getTicketDetailQuery.isSuccess) {
    const ticket = getTicketDetailQuery.data;
    const parsedTicketStatus = TICKET_STATUSES[String(ticket.status)];
    const patient = ticket.patient;
    const patientFullName = `${patient.first_name} ${patient.last_name}`;
    const createdDate = moment.utc(ticket.created_at).local();
    const formatedCreatedDate = createdDate.format('DD [de] MMMM [del] YYYY');
    const parsedRequestedBy = TICKET_REQUESTERS[String(ticket.requested_by)];
    const CaseComponent = TICKET_CASES[String(ticket.type)][String(ticket.requested_by)];

    return (
      <>
        <div className="flex px-4 sm:px-6 md:px-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-md text-primary mr-5"
          >
            <ArrowLeftIcon className="text-primary h-5 w-5" />
          </button>
          <Subtitle className="text-secondary">Regresar</Subtitle>
        </div>
        <br />

        <BasicLayout
          title={
            <div className="flex flex-col md:flex-row md:items-center">
              <span className="mb-2 md:mb-0 md:mr-4">{ticket.title}</span>
              <Tag withDot tag={parsedTicketStatus.tag} color={parsedTicketStatus.color} selected />
            </div>
          }
        >
          <div className="flex flex-col md:flex-row box-border">
            <div className="flex-1 md:pr-8">
              <div className="flex flex-col">
                <DetailItem
                  className="mb-4"
                  Icon={ClipboardIcon}
                  title="Descripción"
                  content={ticket.description}
                />
                <DetailItem
                  className="mb-4"
                  Icon={UserIcon}
                  title="Paciente"
                  content={patientFullName}
                />
                <DetailItem
                  className="mb-4"
                  Icon={ClipboardIcon}
                  title="Fecha de creación"
                  content={formatedCreatedDate}
                />
                <DetailItem
                  className="mb-4"
                  Icon={UserAddIcon}
                  title="¿Quien solicitó?"
                  content={parsedRequestedBy.tag}
                />

                <div className="mb-4">
                  <BasicCard
                    firstText="Acompañamiento"
                    secondText="Ir al detalle del acompañamiento asociado al ticket."
                    icon={<ArrowRightIcon className="h-6 w-6 text-white" />}
                    callback={() => accompanimentCallback(ticket.accompaniment_id)}
                  />
                </div>
                {ticket.session_id ? (
                  <div className="mb-4">
                    <BasicCard
                      firstText="Sesión"
                      secondText="Ir al detalle del la sesión asociada al ticket."
                      icon={<ArrowRightIcon className="h-6 w-6 text-white" />}
                      callback={() => sessionCallback(ticket.session_id)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex-1 md:pr-8">
              <CaseComponent ticket={ticket} refetchTicket={getTicketDetailQuery.refetch} />
            </div>
          </div>
        </BasicLayout>
      </>
    );
  }

  return null;
}
