import { CalendarIcon, ClockIcon, PencilAltIcon } from '@heroicons/react/solid';
import EducationItem from 'components/EducationItem';
import Error from 'components/Error';
import Loader from 'components/Loader';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getAccompanimentSessions } from 'services/accompaniment';
import { accompaniment as accompanimentAtom } from 'state/accompaniment';
import { getToken } from 'state/auth';
import { selectedSession as selectedSessionState } from 'state/session';
import { isArrayEmpty } from 'utils/base';
import { getDateAndTimezone } from 'utils/dates';

export default function PatientSessions() {
  // Global states
  const accompaniment = useRecoilValue(accompanimentAtom); // read-only access
  const token = useRecoilValue(getToken);
  const [, setSelectedSession] = useRecoilState(selectedSessionState);

  // RRD
  const navigate = useNavigate();

  const dateFormat = 'DD/MM/YYYY';

  // Query
  const { data, isSuccess, isLoading, error } = useQuery(
    ['accompanimentSessions', accompaniment.id, token],
    () => getAccompanimentSessions(accompaniment.id, token),
  );

  const buildCard = (session) => {
    return (
      <EducationItem
        key={session.id}
        title={`Sesión ${session.number}`}
        className="col-span-1 border border-1 border-secondary ring ring-0 hover:ring-1 hover:ring-secondary rounded-md "
        callback={() => {
          setSelectedSession(session.id);
          navigate('/ic/sessions/detail');
        }}
        iconSpanItem={[
          {
            icon: <CalendarIcon className="h-6 w-6 mr-2 text-gray-400" />,
            text: (
              <p className="font-body text-xs font-medium text-gray-400 m-0">
                {getDateAndTimezone(session.start_date, dateFormat).sessionDate}
              </p>
            ),
          },
          {
            icon: <ClockIcon className="h-6 w-6 mr-2 text-gray-400" />,
            text: (
              <p className="font-body text-xs font-medium text-gray-400 m-0">
                {getDateAndTimezone(session.start_date, dateFormat).sessionTime}
              </p>
            ),
          },
        ]}
      >
        {<PencilAltIcon className="h-6 w-6 text-gray-400" />}
      </EducationItem>
    );
  };

  return (
    <div className="w-full">
      {isLoading && (
        <div className="grid grid-cols-3">
          <div className="col-span-1 col-start-2 flex justify-center mt-8">
            <Loader className="h-12 w-12 text-secondary" />
          </div>
        </div>
      )}
      {isSuccess &&
        (!isArrayEmpty(data) ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
            {data.map(buildCard)}
          </div>
        ) : (
          <div className="grid grid-cols-3">
            <div className="col-span-1 col-start-2 flex justify-center mt-8">
              <p>No hay sesiones cercanas</p>
            </div>
          </div>
        ))}
      {error && (
        <div className="grid grid-cols-3">
          <div className="col-span-1 col-start-2 flex justify-center mt-8">
            <Error>Ha ocurrido un error</Error>
          </div>
        </div>
      )}
    </div>
  );
}
