export const SPANISH_LOCALE = {
  everyText: 'Todo',
  emptyMonths: 'Todos los meses',
  emptyMonthDays: 'Todos los días del mes',
  emptyMonthDaysShort: 'Día del mes',
  emptyWeekDays: 'Todos los días de la semana',
  emptyWeekDaysShort: 'Día de la semana',
  emptyHours: 'Todas las horas',
  emptyMinutes: 'Todos los minutos',
  emptyMinutesForHourPeriod: 'Todo',
  yearOption: 'Años',
  monthOption: 'Meses',
  weekOption: 'Semanas',
  dayOption: 'Días',
  hourOption: 'Horas',
  minuteOption: 'Minutos',
  rebootOption: 'Reboots',
  prefixPeriod: 'Todo',
  prefixMonths: 'Mes',
  prefixMonthDays: 'Día',
  prefixWeekDays: 'Semana',
  prefixWeekDaysForMonthAndYearPeriod: 'D-S',
  prefixHours: 'Hora',
  prefixMinutes: 'Min',
  prefixMinutesForHourPeriod: 'Min',
  suffixMinutesForHourPeriod: '',
  errorInvalidCron: 'Expresión cron invalida',
  clearButtonText: 'Reiniciar',
  // Order is important, the index will be used as value
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  // Order is important, the index will be used as value
  weekDays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  // cf: https://fr.wikipedia.org/wiki/Mois#Abr%C3%A9viations
  // Order is important, the index will be used as value
  altMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
  // cf: http://bdl.oqlf.gouv.qc.ca/bdl/gabarit_bdl.asp?id=3617
  // Order is important, the index will be used as value
  altWeekDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
};

export const SPANISH_MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const HOST = process.env.REACT_APP_API_HOST;
const API_VERSION = 'v1/';

export const API_URL = `${HOST}${API_VERSION}`;

// Question types
export const QUESTION_TYPES = {
  1: {
    0: {
      label: 'Ninguno',
      description: 'Para nada',
    },
    1: {
      label: 'Raramente',
      description: 'Raro, menos de un día o dos',
    },
    2: {
      label: 'Leve',
      description: 'Algunos días',
    },
    3: {
      label: 'Moderado',
      description: 'Más de la mitad de los días',
    },
    4: {
      label: 'Severo',
      description: 'Casi todos los días',
    },
  },
  3: {
    0: {
      label: 'No',
      description: 'No lo he hecho.',
    },
    1: {
      label: 'Si',
      description: 'Si lo he hecho.',
    },
  },
};
