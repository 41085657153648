export const TASK_STATUS = {
  0: {
    text: 'To do',
  },
  1: {
    text: 'Completado',
  },
  2: {
    text: 'To do',
  },
};
