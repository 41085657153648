import 'moment-timezone';
import 'moment/locale/es';

import moment from 'moment';

import { capitalizeFirstLetter } from './base';

/**
  Function to get spanish date and timezone.
*/
export const getDateAndTimezone = (date) => {
  // We convert the date to a moment object and format to get date and timezone separately
  const sessionDate = moment(date).format('LL');
  const sessionTime = moment(date).format('hh:mm a');

  return { sessionDate, sessionTime };
};

/**
  Function to format to yyyy-mm-dd calendar and make it a moment object.
  This function is specially to use it to print the selected dates in the
  airbnb react dates calendar component.
*/
export const getMomentDateCalendar = (date) => {
  const formatDate = 'YYYY-MM-DD';

  if (!moment.isMoment(date)) {
    return moment(moment(date, formatDate).format(formatDate));
  }
};

/**
  Function to render customize month element.
  This function is specially to use it print and customize months in the
  airbnb react dates calendar component.
*/
export const renderMonthElement = (month) => {
  return (
    <strong className="text-black flex font-bold font-body">
      <small>{capitalizeFirstLetter(month.format('MMMM'))}</small>
    </strong>
  );
};

/**
  Function to see if the date is the same, needs to be a moment object.
  This function is specially to use to highlight the selected dates in the
  airbnb react dates calendar component.
*/
export const isSameDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date() && a.month() === b.month() && a.year() === b.year();
};

/**
  Function to render customize week element.
 This function is specially to use it print and customize the days of the week in the
  airbnb react dates calendar component.
*/
export const renderWeekHeaderElement = (day) => {
  return (
    <strong className="text-black mb-2 font-bold font-body">
      <small>{day}</small>
    </strong>
  );
};

/**
  Function to return if a date is react-picket date is passed
*/
export const isPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  return currentDate.getTime() < selectedDate.getTime();
};
