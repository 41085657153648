import Title from 'components/Title';
import PropTypes from 'prop-types';
import React from 'react';

export default function Day({ day, availabilities, buildAvailability }) {
  return (
    <div className="col-span-1 mt-4">
      <Title className="text-subtitle text-tertiary mb-4">{day}</Title>
      <div className="grid grid-cols-1  gap-4">{availabilities.map(buildAvailability)}</div>
    </div>
  );
}

Day.propTypes = {
  day: PropTypes.string.isRequired,
  availabilities: PropTypes.array.isRequired,
  buildAvailability: PropTypes.func.isRequired,
};
