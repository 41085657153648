import { CalendarIcon, ClockIcon } from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import ContactCard from 'components/ContactCard';
// import ContactCard from 'components/ContactCard';
import QuoteCard from 'components/QuoteCard';
import Subtitle from 'components/Subtitle';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { retrieveDailyPhrase } from 'services/accompaniment';
import { listPsychologistInComingSessions } from 'services/session';
import { auth as authState } from 'state/auth';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function Home() {
  // Global state
  const [auth] = useRecoilState(authState);

  // RRD
  const navigate = useNavigate();

  const retrieveDailyPhraseQuery = useQuery(
    ['retrieveDailyPhrase', auth?.token],
    () => retrieveDailyPhrase(auth?.token),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const listIncomingSessionsQuery = useQuery(
    ['listPsychologistInComingSessions', auth?.token],
    () => listPsychologistInComingSessions(auth?.token),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  if (listIncomingSessionsQuery.isLoading) {
    <BasicLayout
      title={`¡Hola ${auth?.user?.first_name}!`}
      subtitle="Aquí encontrarás un breve resumen de tus pacientes y próximas sesiones."
    >
      Cargando...
    </BasicLayout>;
  }

  const buildItem = (text, Icon, isImage = false) => {
    return {
      children: <p className={`font-body m-0 text-gray-500 text-xs`}>{text}</p>,
      icon: isImage ? (
        <img className="rounded-full h-5 w-5 mr-2" alt={'user'} src={Icon} />
      ) : (
        <Icon className="ml-0 h-5 w-5 text-gray-400 mr-2" />
      ),
    };
  };

  if (listIncomingSessionsQuery.isSuccess) {
    let infoDescription1 = [];
    if (listIncomingSessionsQuery?.data[0]) {
      const session = listIncomingSessionsQuery?.data[0];
      const startDate = moment.utc(session.start_date).local();
      const patientName = `${session.accompaniment.patient.first_name} ${session.accompaniment.patient.last_name}`;
      infoDescription1[0] = buildItem(startDate.format('dddd DD [de] MMMM'), CalendarIcon);
      infoDescription1[1] = buildItem(startDate.format('hh:mm a'), ClockIcon);
      infoDescription1[2] = buildItem(patientName, session.accompaniment.patient.photo_URL, true);
    }

    let infoDescription2 = [];
    if (listIncomingSessionsQuery?.data[1]) {
      const session = listIncomingSessionsQuery?.data[1];
      const startDate = moment.utc(session.start_date).local();
      const patientName = `${session.accompaniment.patient.first_name} ${session.accompaniment.patient.last_name}`;
      infoDescription2[0] = buildItem(startDate.format('dddd DD [de] MMMM'), CalendarIcon);
      infoDescription2[1] = buildItem(startDate.format('hh:mm a'), ClockIcon);
      infoDescription2[2] = buildItem(patientName, session.accompaniment.patient.photo_URL, true);
    }

    const buttonInfo = [
      {
        text: 'Todas las sesiones',
        icon: <CalendarIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
        onClickEvent: () => navigate('sessions'),
      },
    ];

    return (
      <BasicLayout
        title={`¡Hola ${auth?.user?.first_name}!`}
        subtitle="Bienvenido a Mindove, ¡Nos da mucho gusto que estés aquí!"
      >
        <div className="flex flex-col md:flex-row box-border">
          <div className="flex-1 md:pr-8">
            <Subtitle className="text-primary">Frase del día</Subtitle>
            <br />
            {retrieveDailyPhraseQuery.isSuccess ? (
              <QuoteCard
                firstText={retrieveDailyPhraseQuery.data.content}
                secondText={retrieveDailyPhraseQuery.data.author}
                thirdText={retrieveDailyPhraseQuery.data.category}
                className="bg-secondary"
              />
            ) : null}
          </div>
          <div className="flex-1 pt-8 md:pt-0 md:px-8">
            <Subtitle className="text-primary">Próximas sesiones</Subtitle>
            <br />
            {!listIncomingSessionsQuery?.data?.length ? (
              <p className="leading-5 text-tertiary text-md">No tiene sesiones programadas.</p>
            ) : null}
            <div className="border rounded-2xl">
              {listIncomingSessionsQuery?.data[0] ? (
                <ContactCard
                  title="Proxima sesiones"
                  className="cursor-pointer hover:bg-gray-50"
                  infoDescription={infoDescription1}
                  buttonInfo={listIncomingSessionsQuery?.data.length === 1 ? buttonInfo : null}
                />
              ) : null}

              {listIncomingSessionsQuery?.data[1] ? (
                <ContactCard
                  className="cursor-pointer hover:bg-gray-50"
                  infoDescription={infoDescription2}
                  buttonInfo={buttonInfo}
                />
              ) : null}
            </div>
          </div>
        </div>
      </BasicLayout>
    );
  }
  return null;
}
