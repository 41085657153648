import PropTypes from 'prop-types';
import React from 'react';

import Content from './Content';
import Title from './Title';

export default function DetailItem({ className, Icon, title, content }) {
  return (
    <div className={className}>
      <div className="flex items-center ">
        <Icon className="text-primary h-6 w-6 mr-2" />
        <Title className="text-gray-500">{title}</Title>
      </div>
      <Content className="mt-2">
        <span>{content}</span>
      </Content>
    </div>
  );
}

DetailItem.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
