import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import Subtitle from 'components/Subtitle';
import Tag from 'components/Tag';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getDetail as getSessionDetail } from 'services/session';
import { getToken } from 'state/auth';
import { selectedSession as selectedSessionState } from 'state/session';
import { sessionStatuses, sessionStatusesTagColor } from 'utils/parsers';

import { getActionsByStatus } from './actions';
import UserContent from './components/UserContent';

export default function SessionDetail() {
  // Global state
  const token = useRecoilValue(getToken);
  const [selectedSession] = useRecoilState(selectedSessionState);

  // RRD
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  // RQ
  const getSessionDetailQuery = useQuery(
    ['sessionDetail', token, selectedSession],
    () => getSessionDetail(token, selectedSession),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  if (getSessionDetailQuery.isLoading) {
    return <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">Cargando...</div>;
  }
  if (getSessionDetailQuery.isError) {
    return <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">Error...</div>;
  }

  if (getSessionDetailQuery.isSuccess) {
    const session = getSessionDetailQuery.data;

    // Dates
    const startDateUTC = moment(session.start_date);
    const endDateUTC = moment(session.end_date);
    const localTimezone = moment.tz.guess(true);
    const localCountryCode = moment.tz.zone(localTimezone).countries()[0];

    const sessionNumber = session.number;
    const sessionStatus = session.status;
    const sessionStatusText = sessionStatuses[String(sessionStatus)];
    const sessionStatusColor = sessionStatusesTagColor[String(sessionStatus)];
    const actions = Array.from(getActionsByStatus(sessionStatus, session.reviewed));

    const buildAction = (action, index) => {
      return (
        <button
          key={index}
          className="w-full flex justify-between items-center bg-gray-50 shadow-sm rounded-md py-2 px-2 my-2 cursor-pointer hover:shadow-md"
          onClick={() => navigate(action.navigate)}
        >
          <div className="flex-1 flex justify-start items-center">
            {action.icon}
            <p className="ml-4 font-body font-normal leading-5 text-md text-gray-500">
              {action.title}
            </p>
          </div>
          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
        </button>
      );
    };

    const buildTask = (task, index) => {
      return (
        <li key={index} className="py-1 text-gray-500">
          {task.content}
        </li>
      );
    };

    return (
      <BasicLayout title={`Detalle de la sesión ${sessionNumber}`}>
        <div className="flex flex-col">
          <div className="flex">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center text-md text-primary mr-5"
            >
              <ArrowLeftIcon className="text-primary h-5 w-5" />
            </button>
            <Subtitle className="text-secondary hidden md:block">Información</Subtitle>
          </div>
          <br />
          <div className="flex items-center">
            <span className="mr-5">Estatus:</span>{' '}
            <Tag tag={sessionStatusText} color={sessionStatusColor} selected />
          </div>
          <br className="hidden md:block" />
          <div>
            <p className="font-bold font-header text-sm text-gray-500 mb-2">Horarios</p>
            <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-4">
              <UserContent
                user={{
                  full_name: 'Navegador',
                  country: {
                    name: '',
                    code: localCountryCode,
                  },
                  timezone: localTimezone,
                }}
                title="Zona Horaria Actual"
                startDateUTC={startDateUTC}
                endDateUTC={endDateUTC}
              />
              <UserContent
                user={session.psychologist}
                title="Zona Horaria Default"
                startDateUTC={startDateUTC}
                endDateUTC={endDateUTC}
              />
              <UserContent
                user={session.patient}
                title="Paciente"
                startDateUTC={startDateUTC}
                endDateUTC={endDateUTC}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="grid grid-cols-2 lg:grid-cols-3  gap-4">
          <div className="col-span-2 md:col-span-1">
            {session.tasks?.length ? (
              <>
                <Subtitle className="text-secondary">Tareas</Subtitle>
                <ul className="list-inside list-decimal">{session.tasks.map(buildTask)}</ul>
              </>
            ) : null}

            {actions?.length ? (
              <>
                <Subtitle className="text-secondary">Acciones</Subtitle>
                {actions.map(buildAction)}
              </>
            ) : null}
          </div>
          <div className="col-span-2">
            <Outlet context={{ session, refetchSession: getSessionDetailQuery.refetch }} />
          </div>
        </div>
      </BasicLayout>
    );
  }

  return null;
}
