import CometChatComponent from 'components/CometChat';
import React from 'react';
import { useRecoilValue } from 'recoil';
// import { showCallControls as showCallControlsState } from 'state/cometchat';
import { patient as patientAtom } from 'state/patient';

export default function CometChat() {
  // Global states
  const { cometchat_id } = useRecoilValue(patientAtom);
  return (
    <div className="w-full h-[400px] px-4">
      <CometChatComponent showCallControls={true} patientUID={cometchat_id} />
    </div>
  );
}
