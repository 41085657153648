import {
  ACCOMPANIMENT_ACCEPTED,
  ACCOMPANIMENT_CANCELED_BY_ADMIN,
  ACCOMPANIMENT_CANCELED_BY_PATIENT,
  ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST,
  ACCOMPANIMENT_CANCELED_NO_PAYMENT,
  ACCOMPANIMENT_FINALIZED,
  ACCOMPANIMENT_IN_PROGRESS,
  ACCOMPANIMENT_STOPPED_BY_PATIENT,
  ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST,
  ACCOMPANIMENT_STOPPED_NO_PAYMENT,
} from './statuses';

export const STATUS_PATIENT = {
  0: {
    tagColor: 'secondary',
    tag: 'Creado',
    withDot: false,
  },
  1: {
    tagColor: 'nineth',
    tag: 'Inactivo',
    withDot: false,
  },
  2: {
    tagColor: 'sixth',
    tag: 'En revisión',
    withDot: false,
  },
  3: {
    tagColor: 'tertiary',
    tag: 'En tratamiento',
    withDot: true,
  },
};

export const REQUEST_STATUS = {
  0: {
    tagColor: 'tertiary',
    tag: 'Creada',
    withDot: false,
  },
  1: {
    tagColor: 'secondary',
    tag: 'En progreso',
    withDot: false,
  },
  2: {
    tagColor: 'sixth',
    tag: 'Por revisar',
    withDot: false,
  },
  3: {
    tagColor: 'seventh',
    tag: 'En propuesta',
    withDot: true,
  },
  4: {
    tagColor: 'fifth',
    tag: 'Se rechazó',
    withDot: false,
  },
  5: {
    tagColor: 'fifth',
    tag: 'Rechazado por paciente',
    withDot: false,
  },
};

export const ACCOMPANIMENT_STATUS = {
  [ACCOMPANIMENT_ACCEPTED]: {
    tagColor: 'primary',
    tag: 'Aceptado',
    withDot: false,
  },
  [ACCOMPANIMENT_IN_PROGRESS]: {
    tagColor: 'secondary',
    tag: 'En progreso',
    withDot: false,
  },
  [ACCOMPANIMENT_STOPPED_NO_PAYMENT]: {
    tagColor: 'tertiary',
    tag: 'Falto de pago',
    withDot: false,
  },
  [ACCOMPANIMENT_STOPPED_BY_PATIENT]: {
    tagColor: 'fourth',
    tag: 'Detenido por el paciente',
    withDot: true,
  },
  [ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST]: {
    tagColor: 'fourth',
    tag: 'Detenido por el psicólogo',
    withDot: false,
  },
  [ACCOMPANIMENT_CANCELED_NO_PAYMENT]: {
    tagColor: 'fifth',
    tag: 'Cancelado por falta de pago',
    withDot: false,
  },
  [ACCOMPANIMENT_CANCELED_BY_PATIENT]: {
    tagColor: 'fifth',
    tag: 'Cancelado por el paciente',
    withDot: false,
  },
  [ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST]: {
    tagColor: 'fifth',
    tag: 'Cancelado por el psicólogo',
    withDot: false,
  },
  [ACCOMPANIMENT_CANCELED_BY_ADMIN]: {
    tagColor: 'fifth',
    tag: 'Cancelado por un administrador',
    withDot: false,
  },
  [ACCOMPANIMENT_FINALIZED]: {
    tagColor: 'sixth',
    tag: 'Finalizado',
    withDot: false,
  },
};
