import { TrashIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import Title from 'components/Title';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { finalizeSchema } from 'schemas/sessions';
import { createNextSession } from 'services/accompaniment';
import { getToken } from 'state/auth';
import { selectedSession as selectedSessionState } from 'state/session';

export default function Finalize() {
  // Global state
  const token = useRecoilValue(getToken);
  const [selectedSession] = useRecoilState(selectedSessionState);
  // RRD
  const navigate = useNavigate();

  // RQ
  const createNextSessionMutation = useMutation((data) => createNextSession(token, data), {
    onSuccess: () => navigate('/ic/accompaniments'),
    // eslint-disable-next-line no-console
    onError: () => toast.error('Algo salió mal'),
  });

  // RHF
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tasks: [
        {
          content: '',
        },
      ],
    },
    resolver: yupResolver(finalizeSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });

  const onSubmit = (data) => {
    createNextSessionMutation.mutate({ ...data, session_id: selectedSession });
  };

  return (
    <div>
      <Title className="font-body leading-6 text-lg text-primary">Finalizar la sesión</Title>
      <p className="leading-5 text-md text-gray-500">¿Qué actividades ayudarían al paciente?</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          {fields.map((field, index) => (
            <div key={index} className="py-2">
              <TextArea
                withLabel
                textLabel={
                  <div className="flex justify-between items-center">
                    Tarea {index + 1}
                    <button className="p-0" onClick={() => remove(index)}>
                      <TrashIcon className="h-5 w-5 text-red-500" />
                    </button>
                  </div>
                }
                id={`tasks.${index}.content`}
                className="w-full border border-gray-300 rounded-md my-4"
                register={register}
                rows={3}
                error={errors?.tasks?.[`${index}`]?.content?.message}
              />
            </div>
          ))}
        </ul>
        <div className="w-full flex justify-end">
          <div className="w-1/2">
            <Button primary className=" rounded-md" onClick={() => append({ content: '' })}>
              Agregar tarea
            </Button>
          </div>
        </div>
        <br />
        <div className="w-full">
          <p className="text-red-500 mb-2">{errors?.tasks ? errors?.tasks?.message : ''}</p>
          <Button type="submit" className="bg-secondary rounded-md  text-white">
            Finalizar sesión
          </Button>
        </div>
      </form>
    </div>
  );
}
