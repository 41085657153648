import Divider from 'components/Divider';
import IconSpanItem from 'components/IconSpanItem';
import PropTypes from 'prop-types';
import React from 'react';

import Title from './Title';

const iconSpanItemIteration = (array) => {
  let icons = [];
  for (let item in array) {
    icons.push(
      <IconSpanItem icon={array[parseInt(item)]['icon']} key={Math.random()}>
        {array[parseInt(item)]['text']}
      </IconSpanItem>,
    );
  }
  return icons;
};

export default function EducationItem({
  title,
  iconSpanItem,
  divider,
  className,
  children,
  callback,
}) {
  // iconSpanItem is a dynamic objects with array where we send only 2 items, the icon and the span
  return (
    <div
      className={`${className}`}
      role="button"
      onClick={callback}
      onKeyDown={callback}
      tabIndex={0}
    >
      <div className="px-5 py-4">
        <div className="flex justify-between items-center">
          <Title className="text-lg text-gray-500 font-bold">{title}</Title>
          {children}
        </div>
        <div className="flex flex-col mt-2">{iconSpanItemIteration(iconSpanItem)}</div>
      </div>
      {divider && <Divider className="md:hidden" />}
    </div>
  );
}

EducationItem.propTypes = {
  title: PropTypes.string.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  tagColor: PropTypes.string,
  divider: PropTypes.bool,
  iconSpanItem: PropTypes.any,
  callback: PropTypes.func,
};

EducationItem.defaultProps = {
  tagColor: 'primary',
  divider: true,
  callback: () => null,
};
