import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const SESSIONS_PATH = 'sessions/';
const PATH = API_URL + SESSIONS_PATH;

export const listPsychologistInComingSessions = async (token) => {
  const { data } = await axios.get(`${PATH}psychologist/`, {
    headers: headers(token),
  });
  return data;
};

export const getNextSessionStatus = async (token) => {
  const { data } = await axios.get(`${PATH}next/status/`, {
    headers: headers(token),
  });

  return data;
};

export const getDetail = async (token, id) => {
  const { data } = await axios.get(`${PATH}${id}/`, {
    headers: headers(token),
  });
  return data;
};
