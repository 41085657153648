import PropTypes from 'prop-types';
import React from 'react';

export default function Input({
  withLabel,
  textLabel,
  labelClasses = 'text-gray-700',
  placeholder,
  type,
  id,
  rounded,
  textPosition,
  icon,
  leftIcon,
  withUrl,
  register,
  registerConfig,
  autocomplete,
  error,
  disabled,
  required,
}) {
  let roundedClass = 'rounded-md';
  if (rounded) {
    roundedClass = 'rounded-full';
  } else if (withUrl) {
    roundedClass = 'rounded-r-md';
  }

  let borderClasses = 'border-gray-300 focus:ring-secondary focus:border-secondary';
  let _labelClasses = labelClasses;
  if (error) {
    _labelClasses = 'text-error';
    borderClasses = 'border-pink-300 focus:ring-pink-500 focus:border-pink-500 text-pink-500';
  }

  let _type = type ? type : 'text';

  return (
    <div>
      {withLabel && (
        <div className="flex items-center">
          <label htmlFor="email" className={`text-sm font-body font-bold ${_labelClasses}`}>
            {textLabel}
          </label>
          {required ? <p className="ml-1 text-pink-500">*</p> : null}
        </div>
      )}
      <div className="mt-1 relative rounded-md flex">
        {withUrl && (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            http://
          </span>
        )}
        <input
          id={id}
          disabled={disabled}
          {...register(id, registerConfig)}
          type={_type}
          className={`w-full flex-1 min-w-0 block ${roundedClass} ${textPosition} ${borderClasses}  ${
            leftIcon && 'customPlaceholder pl-9'
          } px-3 py-2 sm:text-sm shadow-sm font-default disabled:text-gray-500 disabled:cursor-not-allowed`}
          placeholder={placeholder}
          autoComplete={autocomplete}
        />
        <div
          className={`absolute inset-y-0 pr-3 flex items-center pointer-events-none ${
            leftIcon ? 'left-1.5' : 'right-0'
          } `}
        >
          {icon}
        </div>
      </div>
      {error && <p className="text-left font-default font-medium text-error pt-2">{error}</p>}
    </div>
  );
}

Input.propTypes = {
  withLabel: PropTypes.bool,
  labelClasses: PropTypes.string,
  rounded: PropTypes.bool,
  withUrl: PropTypes.bool,
  textLabel: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  textPosition: PropTypes.string,
  icon: PropTypes.any,
  value: PropTypes.string,
  onChange: PropTypes.any,
  leftIcon: PropTypes.bool,
  register: PropTypes.func,
  registerConfig: PropTypes.object,
  autocomplete: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

Input.defaultProps = {
  withLabel: false,
  rounded: false,
  textLabel: '',
  textPosition: 'text-left',
  placeholder: '',
  type: 'text',
  leftIcon: false,
  withUrl: false,
  autocomplete: 'off',
  error: undefined,
  disabled: false,
};
