import PropTypes from 'prop-types';
import React from 'react';

export default function FormHeader({ title, description, icon }) {
  return (
    <div className="md:col-span-1">
      <div className="px-4 sm:px-0">
        <div className="flex items-center">
          <h3 className="text-lg font-medium leading-6 font-body text-gray-900">{title}</h3>
          {icon}
        </div>
        <p className="mt-1 text-sm text-gray-600">{description}</p>
      </div>
    </div>
  );
}

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any,
  icon: PropTypes.any,
};
