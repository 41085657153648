import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  ChatAlt2Icon,
  HomeIcon,
  MenuAlt2Icon,
  UserGroupIcon,
  UserIcon,
  XIcon,
} from '@heroicons/react/outline';
import {
  ArrowRightIcon,
  CalendarIcon,
  LogoutIcon,
  TicketIcon,
  VideoCameraIcon,
} from '@heroicons/react/solid';
import MindoveLogoWhite from 'assets/icons/logotipo_white_transparent.png';
import Alert from 'components/Alert';
import Button from 'components/Button';
import NavItem from 'components/NavItem';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cometChatInit } from 'services/cometChat';
import { getNextSessionStatus } from 'services/session';
import { auth as authState, getUser } from 'state/auth';
import { showCallControls as showCallControlsState } from 'state/cometchat';
import { patient as patientAtom } from 'state/patient';
import { SESSION_IN_PROGRESS, SESSION_PAYED } from 'utils/statuses';

const navigation = [
  { name: 'Inicio', href: '/ic', icon: HomeIcon, current: true },
  { name: 'Disponibilidad', href: 'availability', icon: CalendarIcon, current: false },
  { name: 'Acompañamientos', href: 'accompaniments', icon: UserGroupIcon, current: false },
  { name: 'Sala de sesiones', href: 'sessions', icon: ChatAlt2Icon, current: false },
  { name: 'Tickets', href: 'tickets', icon: TicketIcon, current: false },
  { name: 'Perfil', href: 'profile', icon: UserIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function App() {
  // Global state
  const user = useRecoilValue(getUser);
  const [auth, setAuth] = useRecoilState(authState);
  const [, setPatient] = useRecoilState(patientAtom);
  const [, setShowCallControls] = useRecoilState(showCallControlsState);
  // Local state
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [session, setSession] = useState(null);
  const [fetchNextSessionInterval, setFetchNextSessionInterval] = useState(60000);

  // RQ
  const navigate = useNavigate();

  // TODO: Make util func
  const getTimeRemaining = (start_date) => {
    const now = moment();
    const sessionStartDate = moment.utc(start_date);
    return sessionStartDate.diff(now, 'minutes');
  };

  // RQ
  const onSuccessGetNextSessionStatus = (data) => {
    if (!Object.keys(data).length) {
      setFetchNextSessionInterval(60000);
      setShowCallControls(false);
      return;
    }
    setSession(data);
    const startDate = moment.utc(data.start_date).local();
    const timeRemaining = getTimeRemaining(startDate);
    if (timeRemaining <= 10 && timeRemaining > 3) {
      setShowCallControls(false);
      setFetchNextSessionInterval(30000);
    } else if (timeRemaining <= 3 && timeRemaining > 0) {
      setShowCallControls(true);
      setFetchNextSessionInterval(10000);
    } else if (timeRemaining <= 0 && timeRemaining > -59) {
      setShowCallControls(true);
      setFetchNextSessionInterval(30000);
    } else {
      setShowCallControls(false);
      setFetchNextSessionInterval(60000);
    }
  };

  useQuery(['getNextSessionStatus', auth?.token], () => getNextSessionStatus(auth?.token), {
    enabled: !!auth?.token,
    refetchInterval: fetchNextSessionInterval,
    onSuccess: onSuccessGetNextSessionStatus,
  });

  useEffect(() => {
    const initCometChat = async (uid) => {
      await cometChatInit(uid);
    };

    if (user?.cometchat_id) {
      initCometChat(user?.cometchat_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userNavigation = [{ name: 'Sign out', onClick: () => setAuth() }];

  let alertText = '';
  let alertType = 'info';
  let timeRemaining = 11;
  let showAlert = false;
  let alertCallback = () => null;
  if (session && (session?.status == SESSION_PAYED || session?.status == SESSION_IN_PROGRESS)) {
    const startDate = moment.utc(session.start_date).local();
    timeRemaining = getTimeRemaining(startDate);
    showAlert = timeRemaining <= 10 && timeRemaining >= -60;
    alertCallback = () => {
      setPatient(session.accompaniment.patient);
      navigate('/ic/accompaniments/detail/cometchat');
    };
    if (timeRemaining > 1) {
      alertText = `Tu sesión comenzara en ${timeRemaining} minutos`;
    } else if (timeRemaining == 1) {
      alertType = 'session';
      alertText = `Tu sesión comenzara en ${timeRemaining} minuto`;
    } else if (timeRemaining <= 0) {
      alertType = 'session';
      alertText = `Tu sesión ya comenzo, dirigete a la sala del chat`;
    }
  }

  // AQUI JAVI
  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img alt="Mindove" src={MindoveLogoWhite} />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <NavItem
                      key={item.name}
                      name={item.name}
                      href={item.href}
                      classNames={classNames}
                      Icon={item.icon}
                      IconClassName={
                        'mr-4 flex-shrink-0 h-6 w-6 text-white hover:text-white focus:text-secondary'
                      }
                    />
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 drop-shadow-md z-100">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow pt-5 bg-primary overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img alt="Mindove" src={MindoveLogoWhite} />
          </div>
          <div className="mt-5 flex-1 flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navigation.map((item) => (
                <NavItem
                  key={item.name}
                  name={item.name}
                  href={item.href}
                  classNames={classNames}
                  Icon={item.icon}
                  IconClassName={
                    'mr-3 flex-shrink-0 h-6 w-6 text-white hover:text-white focus:text-white'
                  }
                />
              ))}
            </nav>
            <div className="my-2 p-2">
              <Button
                className="border border-1 rounded-md border-white text-white"
                icon={<LogoutIcon className="h-6 w-6 text-white p-0 mr-4" />}
                onClick={() => setAuth()}
              >
                Cerrar Sesión
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-primary sm:block md:hidden">
          <button
            type="button"
            className="px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex"></div>
            <div className="ml-4 flex items-center md:ml-6">
              {/* <button
                type="button"
                className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button> */}

              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src={user?.photo_URL} alt="" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-primary ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            onClick={item.onClick}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'w-full block px-4 py-2 text-sm text-white',
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main>
          <Transition
            show={showAlert}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Alert
              type={alertType}
              text={alertText}
              showActionIcon={true}
              Icon={VideoCameraIcon}
              ActionIcon={ArrowRightIcon}
              actionCallback={alertCallback}
            />
          </Transition>
          <div className="py-6">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
