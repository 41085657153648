import PropTypes from 'prop-types';
import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TabContainer({ tabs, activeTab, setActiveTab, alwaysMobile }) {
  return (
    <div>
      <div className={`${alwaysMobile ? '' : 'md:hidden'}`}>
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs[`${activeTab}`].name}
          onChange={(e) => setActiveTab(Number(e.target.value))}
        >
          {tabs.map((tab, index) => (
            <option key={tab.name} value={index}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className={`${alwaysMobile ? 'hidden' : 'hidden md:block'}`}>
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <button
                key={tab.name}
                onClick={() => setActiveTab(index)}
                className={classNames(
                  activeTab === index
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 text-content',
                )}
                aria-current={activeTab === index ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    activeTab === index
                      ? 'text-primary'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5',
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

TabContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func.isRequired,
  alwaysMobile: PropTypes.bool,
};

TabContainer.defaultProps = {
  tabs: [],
  activeTab: 0,
  alwaysMobile: false,
};
