import BasicLayout from 'components/BasicLayout';
import Content from 'components/Content';
import Loader from 'components/Loader';
import TabContainer from 'components/TabContainer';
import Title from 'components/Title';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getAccompanimentDetail } from 'services/accompaniment';
import { accompaniment as accompanimentAtom } from 'state/accompaniment';
import { getToken } from 'state/auth';
import { ACCOMPANIMENT_DETAIL_MENU } from 'utils/menus/accompaniment';

export default function AccompanimentDetailContainer() {
  // Global states
  const token = useRecoilValue(getToken);
  const [accompanimentState] = useRecoilState(accompanimentAtom);
  console.log('🚀 ~ AccompanimentDetailContainer ~ accompanimentState:', accompanimentState);

  // LOCAL STATES
  const [activeTab, setActiveTab] = useState(0);

  // RRD
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const paths = window.location.pathname.split('/');
    const path = paths[paths.length - 1];
    let currentMenuItem = 0;
    ACCOMPANIMENT_DETAIL_MENU.forEach((menuItem, index) => {
      if (menuItem.path === path) {
        currentMenuItem = index;
      }
    });
    setActiveTab(currentMenuItem);
  }, []);

  // React Query
  const {
    data: accompaniment,
    isLoading,
    isSuccess,
  } = useQuery(
    ['getAccompaniment', token, accompanimentState.id],
    () => getAccompanimentDetail(token, accompanimentState.id),
    {
      enabled: !!accompanimentState,
      refetchOnWindowFocus: false,
    },
  );

  const onClickTabHandler = (activeTab) => {
    setActiveTab(activeTab);
    const path = ACCOMPANIMENT_DETAIL_MENU[String(activeTab)].path;
    if (location.pathname.includes(path)) return;
    navigate(path);
  };

  if (isLoading)
    return (
      <div className="grid grid-cols-3">
        <div className="col-span-1 col-start-2 flex justify-center mt-8">
          <Loader className="h-12 w-12 text-secondary" />
        </div>
      </div>
    );

  if (!isSuccess && !isLoading) return <>Algo salió mal</>;

  return (
    <BasicLayout>
      <div>
        <Title>{accompaniment.title}</Title>
        <Content className="text-gray-500">{accompaniment.description}</Content>
      </div>
      {/* <ImageCard title={`${data.title}`} className="bg-primary bg-img-02 z-0">
        <p className="font-body text-white text-sm font-light mt-2">{accompaniment.description}</p>
      </ImageCard> */}
      <br />
      <TabContainer
        tabs={ACCOMPANIMENT_DETAIL_MENU}
        activeTab={activeTab}
        setActiveTab={onClickTabHandler}
      />
      <div className="grid mt-8">
        <Outlet context={accompaniment} />
      </div>
    </BasicLayout>
  );
}
