import BasicLayout from 'components/BasicLayout';
import Loader from 'components/Loader';
import TabContainer from 'components/TabContainer';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listByPsychologist as listTicketsByPsychologist } from 'services/tickets';
import { getToken } from 'state/auth';
import { selectedTicket } from 'state/ticket';
import { TICKET_STATUS_MENU, TICKET_TYPES_MENU } from 'utils/menus/tickets';

import TicketCard from './Card';

export default function TicketsList() {
  // Global states
  const token = useRecoilValue(getToken);
  const [, setSelectedTicket] = useRecoilState(selectedTicket);

  // LOCAL STATES
  const [statusActiveTab, setStatusActiveTab] = useState(0);
  const [typeActiveTab, setTypeActiveTab] = useState(0);
  const [status, setStatus] = useState(TICKET_STATUS_MENU[0].status);
  const [type, setType] = useState(TICKET_TYPES_MENU[0].type);

  // RRD
  const navigate = useNavigate();

  // REACT QUERY
  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['listTicketsByPsychologist', token, status, type],
    () => listTicketsByPsychologist(token, status, type),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    const selectedTab = TICKET_STATUS_MENU[`${statusActiveTab}`];
    setStatus(selectedTab.status);
  }, [statusActiveTab]);

  useEffect(() => {
    const selectedTab = TICKET_TYPES_MENU[`${typeActiveTab}`];
    setType(selectedTab.type);
  }, [typeActiveTab]);

  useEffect(() => {
    refetch();
  }, [status, type, refetch]);

  const onClickHandler = (ticketID) => {
    setSelectedTicket(ticketID);
    navigate(`detail`);
    return null;
  };

  return (
    <BasicLayout title="Tickets">
      <div>
        <TabContainer
          alwaysMobile
          tabs={TICKET_TYPES_MENU}
          activeTab={typeActiveTab}
          setActiveTab={setTypeActiveTab}
        />
        <TabContainer
          tabs={TICKET_STATUS_MENU}
          activeTab={statusActiveTab}
          setActiveTab={setStatusActiveTab}
        />
        {isLoading ? (
          <div className="w-full flex justify-center items-center mt-4">
            <Loader className="h-12 w-12 text-tertiary" />
          </div>
        ) : null}

        {isSuccess ? (
          <div className="flex flex-col md:grid grid-cols-2 auto-rows-fr gap-x-3 box-border">
            {data.length ? (
              data.map((ticket, index) => (
                <TicketCard key={index} ticket={ticket} index={index} callback={onClickHandler} />
              ))
            ) : (
              <p className="mt-4 leading-5 text-gray-500 text-content">
                Aun no tiene tickets con estos filtros
              </p>
            )}
          </div>
        ) : null}
      </div>
    </BasicLayout>
  );
}
