import './index.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { Analytics } from '@vercel/analytics/react';
// import AccompanimentRequest from 'pages/AccompanimentRequests';
// import AccompanimentRequestDetail from 'pages/AccompanimentRequests/Detail';
// import AccompanimentRequestAccept from 'pages/AccompanimentRequests/Detail/Accept';
// import AccompanimentRequestDetailContainer from 'pages/AccompanimentRequests/Detail/container';
// import AccompanimentRequestReject from 'pages/AccompanimentRequests/Detail/Reject';
// import AccompanimentRequestList from 'pages/AccompanimentRequests/List';
import Accompaniments from 'pages/Accompaniments';
import AccompanimentDetailContainer from 'pages/Accompaniments/Detail';
// import AccompanimentActions from 'pages/Accompaniments/Detail/Actions';
// import CancelAccompaniment from 'pages/Accompaniments/Detail/Actions/Actions/cancel';
// import ChangeAccompanimentPeriodicity from 'pages/Accompaniments/Detail/Actions/Actions/changePeriodicity';
// import ExtendAccompaniment from 'pages/Accompaniments/Detail/Actions/Actions/extend';
// import PauseAccompaniment from 'pages/Accompaniments/Detail/Actions/Actions/pause';
// import ShortenAccompaniment from 'pages/Accompaniments/Detail/Actions/Actions/shorten';
import PatientAutodiagnosis from 'pages/Accompaniments/Detail/Autodiagnosis';
import CometChat from 'pages/Accompaniments/Detail/CometChat';
import AccompanimentDetail from 'pages/Accompaniments/Detail/Detail';
import PatientSessions from 'pages/Accompaniments/Detail/Sessions';
import Tasks from 'pages/Accompaniments/Detail/Tasks';
import AccompanimentList from 'pages/Accompaniments/List';
import RequireAuth from 'pages/Authentication/RequireAuth';
import Availability from 'pages/Availability';
import CustomQueryClientProvider from 'pages/CustomQueryClientProvider';
import Home from 'pages/Home';
import Landing from 'pages/Landing';
import Login from 'pages/Login';
import Profile from 'pages/Profile';
import CompleteProfile from 'pages/Profile/CompleteProfile';
import ProfileDetail from 'pages/Profile/Detail';
import Sessions from 'pages/Sessions';
import CalendarSessions from 'pages/Sessions/Calendar';
import SessionDetail from 'pages/Sessions/Detail';
import SessionFinalize from 'pages/Sessions/Detail/Actions/Finalize';
import SessionReschedule from 'pages/Sessions/Detail/Actions/Reschedule';
import Tickets from 'pages/Tickets';
import TicketDetail from 'pages/Tickets/Detail';
import TicketsList from 'pages/Tickets/List';
import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Analytics />
    <RecoilRoot>
      <CustomQueryClientProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="ic"
              element={
                <RequireAuth>
                  <App />
                </RequireAuth>
              }
            >
              <Route index element={<Home />} />
              <Route path="availability" element={<Availability />} />
              {/* <Route path="requests" element={<AccompanimentRequest />}>
                <Route index element={<AccompanimentRequestList />} />
                <Route path="detail" element={<AccompanimentRequestDetailContainer />}>
                  <Route index element={<AccompanimentRequestDetail />} />
                  <Route path="accept" element={<AccompanimentRequestAccept />} />
                  <Route path="reject" element={<AccompanimentRequestReject />} />
                </Route>
              </Route> */}
              <Route path="accompaniments" element={<Accompaniments />}>
                <Route index element={<AccompanimentList />} />
                <Route path="detail" element={<AccompanimentDetailContainer />}>
                  <Route path="" element={<AccompanimentDetail />} />
                  <Route path="cometchat" element={<CometChat />} />
                  <Route path="autodiagnosis" element={<PatientAutodiagnosis />} />
                  <Route path="sessions" element={<PatientSessions />} />
                  <Route path="tasks" element={<Tasks />} />
                  {/* <Route path="actions" element={<AccompanimentActions />}>
                    <Route index element={<></>} />
                    <Route path="change-periodicity" element={<ChangeAccompanimentPeriodicity />} />
                    <Route path="stop" element={<PauseAccompaniment />} />
                    <Route path="extend" element={<ExtendAccompaniment />} />
                    <Route path="shorten" element={<ShortenAccompaniment />} />
                    <Route path="cancel" element={<CancelAccompaniment />} />
                  </Route> */}
                </Route>
              </Route>
              <Route path="sessions" element={<Sessions />}>
                <Route index element={<CalendarSessions />} />
                <Route path="detail" element={<SessionDetail />}>
                  <Route path="reschedule" element={<SessionReschedule />} />
                  <Route path="finalize" element={<SessionFinalize />} />
                </Route>
              </Route>
              <Route path="tickets" element={<Tickets />}>
                <Route index element={<TicketsList />} />
                <Route path="detail" element={<TicketDetail />} />
              </Route>
              <Route path="profile" element={<Profile />}>
                <Route index element={<ProfileDetail />} />
                <Route path="detail" element={<CompleteProfile />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </CustomQueryClientProvider>
    </RecoilRoot>
    <Toaster
      position="top-right"
      toastOptions={{
        // Define default options
        className: '',
        duration: 3000,
        style: {
          background: '#eaeaea',
          color: '#111827',
        },
      }}
    />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
