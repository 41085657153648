import Content from 'components/Content';
import Title from 'components/Title';
import PropTypes from 'prop-types';
import React from 'react';
import { STATUS_APPROVED, STATUS_REJECTED } from 'utils/constants/tickets';

export default function PsychologistCancel({ ticket }) {
  return (
    <div>
      <br />
      {ticket.status === STATUS_APPROVED ? (
        <>
          <Title className="text-lg mb-2">El acompañamiento fue cancelado</Title>
        </>
      ) : null}
      {ticket.status === STATUS_REJECTED ? (
        <>
          <Title className="text-lg mb-2">La solicitud de cancelación fue rechazada</Title>
          <Content>
            <span>Contacte a soporte si tiene alguna duda.</span>
          </Content>
        </>
      ) : null}
    </div>
  );
}

PsychologistCancel.propTypes = {
  ticket: PropTypes.object.isRequired,
  refetchTicket: PropTypes.func.isRequired,
};
