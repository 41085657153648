// TODO: Remove hardcoded price
export const PSYCHOLOGIST_DEGREES = {
  0: {
    degree: 'Becario',
    color: 'fourth',
    sessionCost: '20',
  },
  1: {
    degree: 'Dover',
    color: 'tertiary',
    sessionCost: '59',
  },
  2: {
    degree: 'SuperDover',
    color: 'secondary',
    sessionCost: '69',
  },
  3: {
    degree: 'UltraDover',
    color: 'primary',
    sessionCost: '79',
  },
};
